import React from 'react';
import Heading from '../../components/UI/Heading';
import Text from '../../components/UI/Text';
import View from '../../components/UI/View';

const SectionTitle = ({ currentSection, counter }) => {

    //if (!counter || counter>0 || !currentSection) return;

    if (!counter || !currentSection) return;

    //if (counter.question > 0) return;

    //console.log(currentSection)

    const title = `${counter.section+1}. ${currentSection.name}`;
  
    return (

      <View style={{marginTop:15,}}>

      <Heading>{title}</Heading>

      {currentSection.description && counter.question === 0 &&

        <Text>

          {currentSection.description}
          
        </Text>

      }

    </View>



  );
};

export default SectionTitle;

