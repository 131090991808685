import bluecorner from '../../assets/images/bluecorner.png';
import bluesquare from '../../assets/images/bluesquare.png';
const Background = () => {
    
    return (
      <>

    <img src={bluecorner} style={{
        position:'absolute', 
        width:100,
        maxWidth:'15%',
        height:'auto',
        top:0, 
        right: 0,
        }} alt="" />


    <img src={bluesquare} style={{
        position:'absolute', 
        width:20,
        height:'auto',
        top:300, 
        left: 0,
        }} alt="" />


      
      </>
        
   
        )}

        export default Background;