import React, { useState, useEffect, useContext } from 'react';
import ShortAnswer from '../../components/Report/ShortAnswer';
import Location from '../../components/Report/Location';
import LongAnswer from '../../components/Report/LongAnswer';
import SingleChoice from '../../components/Report/SingleChoice';
import MultipleChoice from '../../components/Report/MultipleChoice';
import FileUpload from './FileUpload';

import { UserContext } from '../../store/user-context'

const QuestionForm = ({ currentQuestion, responses, handleResponse, handleMultipleResponses, handleUpload, scrollIntoView }) => {

    const UserCtx = useContext(UserContext);
    const [defaultResponse, setDefaultResponse] = useState();
    const [defaultResponses, setDefaultResponses] = useState();
    const [otherDetails, setOtherDetails] = useState('');
    const [defaultAudio, setDefaultAudio] = useState();

    //const currentResponse = responses.filter ( response => (response.id?.indexOf(currentQuestion.id) !==-1))
    //console.log('currentResponse',currentResponse)
    //console.log('responses ',responses)
    //console.log(currentQuestion);
    //console.log('QuestionForm',responses);
    //console.log('other details',otherDetails)
    //console.log(currentQuestion.otherlabel)
    //console.log('defaultResponses',defaultResponses)
    //console.log('defaultAudio',defaultAudio);

    useEffect(() => {
        if (!currentQuestion || !UserCtx) return;
        setDefaultResponse();
        setDefaultResponses([]);
        setDefaultAudio();
        //see if question has been answered previously - use IndexOf because response ids to muitple choice questions are saved as questionid+0 etc. response will be an array for mc questions
        if (currentQuestion.type === "Multiple choice" ) {
            const resps = responses.filter ( response => (response.id?.indexOf(currentQuestion.id) !==-1 && response.response !=='')).map(({column}) => column);
            //the 'other' checkbox has been saved to the database with the question text, so remove the question text here before passing it on
            const trimmedResponses = resps.map(resp => {
                return ( 
                    currentQuestion.other && resp === currentQuestion.text+' '+currentQuestion.otherlabel ?
                    currentQuestion.otherlabel : 
                    resp
                )
            })
            setDefaultResponses( trimmedResponses ) ;
            //get any audio for other responses - Multiple choice questions
            if (currentQuestion.otherlabel) {
                const otheraudioResponse = responses.find ( response => response.id === currentQuestion.id+' '+currentQuestion.otherlabel + " audio" );
                //console.log('MC otheraudioResponse',otheraudioResponse)
                if (otheraudioResponse) setDefaultAudio(otheraudioResponse.response)
            }
        } else if (currentQuestion.type === "Location" ) {
            //get previous responses if there are any
            const resps = responses.filter ( response => (
                (response.id === currentQuestion.id+" suburb" || response.id === currentQuestion.id+" state" || response.id === currentQuestion.id+" postcode") &&  response.response !==''));
            if (resps.length > 0) {
                setDefaultResponses( resps ) ;
            } else if (UserCtx.user) {
                //get default / prepoluated answers from user profile 
                const userLocation = [
                    {id: currentQuestion.id + " suburb", column: currentQuestion.text + " suburb", response: UserCtx.user[currentQuestion.id + " suburb"]},
                    {id: currentQuestion.id + " state", column: currentQuestion.text + " state", response: UserCtx.user[currentQuestion.id + " state"]},
                    {id: currentQuestion.id + " postcode", column: currentQuestion.text + " postcode", response: UserCtx.user[currentQuestion.id + " postcode"]},
                ];
                setDefaultResponses(userLocation); 
                //update response state
                handleMultipleResponses( userLocation )
            }
        } else  {
            //other question types - the response id will be the same as the  question id (only one value recorded per question)
            let response =   responses.find ( response => response.id === currentQuestion.id );
            const audioResponse = responses.find ( response => response.id === currentQuestion.id + " audio" );
            //if there has been an audio response add it to default response
            if ( audioResponse ) {
                if (response) { 
                    response.audio = audioResponse.response;
                } else {
                    //if there is no text response we have to set up a response object and add the audio to it
                    response = { audio: audioResponse.response }
                }
            }
            //get any audio for other responses - all other question types
            if (currentQuestion.otherlabel) {
                const otheraudioResponse = responses.find ( response => response.id === currentQuestion.id+' '+currentQuestion.otherlabel + " audio" );
               //console.log('SC otheraudioResponse',otheraudioResponse)
                if (otheraudioResponse) setDefaultAudio(otheraudioResponse.response)
            }
            const imageResponse = responses.find ( response => response.id === currentQuestion.id + " image" );
            //if there has been an image response add it to default response
            if ( imageResponse ) {
                if (response) { 
                    response.image = imageResponse.response;
                } else {
                    //if there is no text response we have to set up a response object and add the image to it
                    response = { image: imageResponse.response }
                }
            }
            if ( response ) { 
                setDefaultResponse( response ) 
            } else if (UserCtx.user){
                //get default / prepoluated answers from user profile - doesn't cater for multiple choice questions, only single choice and short answer
                if (UserCtx.user[currentQuestion.id]) {
                    setDefaultResponse({response: UserCtx.user[currentQuestion.id]}); 
                    //update response state
                    handleResponse( currentQuestion.id, currentQuestion?.text, UserCtx.user[currentQuestion.id])
                }
            }
        }
        //get text for other details box
        const otherResponse = responses.find ( response => response.id === currentQuestion.id+'otherdetails');
        otherResponse?.response ? setOtherDetails(otherResponse?.response) : setOtherDetails('');
      }, [currentQuestion, UserCtx]);

     if (currentQuestion.type === "Short response")  return ( 
       <ShortAnswer 
            key={currentQuestion.id}
            currentQuestion={currentQuestion} 
            handleResponse={handleResponse}
            defaultResponse={defaultResponse}
            /> 
    );
 
    if (currentQuestion.type === "Location")  return ( 
       <Location 
            currentQuestion={currentQuestion} 
            handleResponse={handleResponse}
            defaultResponses={defaultResponses}
            /> 
    );  
    
    if (currentQuestion.type === "Long response")  return ( 
     <LongAnswer
            currentQuestion={currentQuestion} 
            handleResponse={handleResponse}
            defaultResponse={defaultResponse}
            handleUpload={handleUpload}
            /> 
    );
    
    

    if (currentQuestion.type === "Multiple choice")  return ( 
    <MultipleChoice 
            key={currentQuestion.id}
            currentQuestion={currentQuestion} 
            handleMultipleResponses={handleMultipleResponses}
            defaultResponses={defaultResponses}
            otherDetails={otherDetails}
            setOtherDetails = {setOtherDetails}
            scrollIntoView={scrollIntoView}
            handleUpload={handleUpload}
            defaultAudio={defaultAudio}
            responses={responses}
            /> 
    );
     
    if (currentQuestion.type === "Image upload")  return ( 
       <FileUpload
            currentQuestion={currentQuestion} 
            defaultResponse={defaultResponse}
            handleUpload={handleUpload}
            /> 
    );  


if (currentQuestion.type === "Single choice")  return ( 
    <SingleChoice 
        currentQuestion={currentQuestion} 
        handleResponse={handleResponse}
        defaultResponse={defaultResponse}
        otherDetails={otherDetails}
        setOtherDetails = {setOtherDetails}
        scrollIntoView={scrollIntoView}
        handleUpload={handleUpload}
        defaultAudio={defaultAudio}
        /> 
  );
    
  return <></>

};

export default QuestionForm;

