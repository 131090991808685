import React from 'react';

import View from '../../components/UI/View';
import Text from '../../components/UI/Text';
import Button from '../../components/UI/Button';
import { Colours } from '../../config';
import QuestionDot from '../../components/ReportNav/QuestionDot';

import VfmWPUpUHVA0ENy4XpME from '../../assets/images/icon_VfmWPUpUHVA0ENy4XpME.png';
import qwqhwtMGRoabDcRHX4Bo from '../../assets/images/icon_qwqhwtMGRoabDcRHX4Bo.png';
import Gxep4XyRUNkXMvCdzroK from '../../assets/images/icon_Gxep4XyRUNkXMvCdzroK.png';
import g8YfJYVtKzNCy1KgXylP from '../../assets/images/icon_g8YfJYVtKzNCy1KgXylP.png';
import s5uIZT7NqkPAQ2NU3hbBw from '../../assets/images/icon_5uIZT7NqkPAQ2NU3hbBw.png';
import ZW1RuuvfcHcqwIPYbpSk from '../../assets/images/icon_ZW1RuuvfcHcqwIPYbpSk.png';

const CurrentSectionNavTile = ({ 
  onPress, 
  section, 
  counter, 
  sectionQuestions, 
  currentQuestion, 
  navOpen,
  responses
 }) => {

  let imageSource = null;

  switch (section.id) {
    case 'VfmWPUpUHVA0ENy4XpME': imageSource = VfmWPUpUHVA0ENy4XpME; break;
    case 'qwqhwtMGRoabDcRHX4Bo': imageSource = qwqhwtMGRoabDcRHX4Bo; break;
    case 'Gxep4XyRUNkXMvCdzroK': imageSource = Gxep4XyRUNkXMvCdzroK; break;
    case 'g8YfJYVtKzNCy1KgXylP': imageSource = g8YfJYVtKzNCy1KgXylP; break;
    case '5uIZT7NqkPAQ2NU3hbBw': imageSource = s5uIZT7NqkPAQ2NU3hbBw; break;
    case 'ZW1RuuvfcHcqwIPYbpSk': imageSource = ZW1RuuvfcHcqwIPYbpSk; break;
    default: imageSource = null;
}
  
  const TileImage = () => {
    if ( imageSource ) return (
      <img 
        src={imageSource} 
        style={{
          width:37,
          height:37,
          marginBottom:5,
          objectFit:'contain',
          
        }} 
        alt=''
      />
    )
  }

  const TileText = () => {
    return (
      <Text 
        bold 
        style={{
          fontSize:12,
          textAlign:'center',
          fontWeight:'bold',
          fontFamily:'roboto',
          maxWidth:300,
          width:'auto',
          paddingLeft:10,
          paddingRight:10,
          color:navOpen ? Colours.colour2 : Colours.white,
          backgroundColor: navOpen ? Colours.offwhite : 'transparent'
          }}
        >
          {counter?.section+1}.{section.name}
        </Text>
    )
  }

  if (!section) return;

  return (
    
    <Button 
      clear 
      onPress={onPress} 
      style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        margin:10,
        width:'33%',
      }}
      >

     <TileImage/>
  
      <TileText/>

      <View 
        style={{
          display:'flex',
          width:'100%',
          flexDirection:'row',
          justifyContent:'center',
          gap:2,
          paddingTop:3
        }}>

        { sectionQuestions?.map( question => (

        <QuestionDot 
          key={question.id} 
          question={question}
          responses={responses}
          currentQuestion={currentQuestion}
          />
            
         ) ) 
        }
     </View>

    </Button>

  );
};

export default CurrentSectionNavTile;

