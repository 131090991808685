import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import Button from '../UI/Button';

import classes from './Alert.module.css';
import parse from 'html-react-parser';

//accessibility
//using portals to move backdrop content outside the content root element
const AlertBackdrop = ({onConfirm}) =>{
  return(
    <div className={classes.backdrop} onClick={onConfirm} />
  )
}

//using portals to move overlay content outside the content root element
const AlertModalOverlay = ({ onConfirm, title, message, onCancel, cancelLabel, submitLabel, onThird, thirdLabel }) => {

  //display three options (vertical layout)
  if (onThird) return (

    <div className = { classes.alert }>

      <div className = { classes.alertContent } >

      <div className= { classes.alertHeader }>  {title}  </div>

      <div className={classes.alertText}>

        {message && parse(message)}
        
      </div>

      <div className={`${classes.alertFooter} ${classes.alertFooterColumn}`}>

            <Button 
            primary 
            onPress={onConfirm}
            style={{
              flex:1,
              fontSize: 14
            }}>
             {`${submitLabel ? submitLabel : 'OK'}`}
            </Button>

          <Button 
            primary
            onPress={onThird}
            style={{
              flex:1,
              fontSize: 14
            }}>
              {`${thirdLabel ? thirdLabel : 'OK'}`}
            </Button>

            {onCancel && 
          <Button 
            grey
            onPress={onCancel}
            style={{
              flex:1,
              fontSize:14
            }}>
              {`${cancelLabel ? cancelLabel : 'Cancel'}`}
            </Button>
          }


      </div>

      </div>

    </div>
  )

  //display two or one options (horizontal layout)
  return(


    <div className = { classes.alert }>

      <div className = { classes.alertContent } >

      <div className= { classes.alertHeader }>  {title}  </div>

      <div className={classes.alertText}>

        {message && parse(message)}
        
      </div>

      <div className={`${classes.alertFooter}`}>

        {onCancel && 
          <Button 
            grey
            onPress={onCancel}
            style={{
              flex:1,
              fontSize: onThird ? 14 : 'inherit'
            }}>
              {`${cancelLabel ? cancelLabel : 'Cancel'}`}
            </Button>
          }

          <Button 
            primary 
            onPress={onConfirm}
            style={{
              flex:1,
              fontSize: thirdLabel ? 14 : 'inherit'
            }}>
             {`${submitLabel ? submitLabel : 'OK'}`}
            </Button>


      </div>

      </div>

    </div>
   
  )
}

const Alert = ( { onConfirm, title,  message, onCancel, cancelLabel, submitLabel, onThird, thirdLabel  } ) => {
  return (
    <Fragment>
      
      {ReactDOM.createPortal(
        <AlertBackdrop 
        onConfirm={onConfirm}
        />, 
        document.getElementById('root-backdrop')
      )}

      {ReactDOM.createPortal(
        <AlertModalOverlay 
        title={title} 
        onConfirm={onConfirm} 
        onCancel={onCancel}
        message={message} 
        cancelLabel={cancelLabel}
        submitLabel={submitLabel}
        onThird={onThird}
        thirdLabel={thirdLabel}
        />, 
        document.getElementById('root-overlay')
      )}
    </Fragment>
  );
};

export default Alert;
