import React, {useState,useEffect} from 'react';
import Alert from '../../components/Modals/Alert';
import View from '../../components/UI/View';
import Text from '../../components/UI/Text';
import Button from '../../components/UI/Button';
import QuestionDot from '../../components/ReportNav/QuestionDot';
import CancelIcon from '@mui/icons-material/Cancel';
import { Colours } from '../../config';

const QuestionMenu = ({ 
  questions, 
  navOpen, 
  setNavOpen,  
  previewSection, 
  sections, 
  counter, 
  setCounter, 
  currentQuestion,
  responses,
  complusoryQuestionsComplete
 }) => {

  const [preview, setPreview] = useState()
  const [alertMessage, setAlertMessage] = useState();

  const questionClickHandler = (sectionid, questionid) => {
    if (complusoryQuestionsComplete) {
      const sectionCounter = sections.findIndex(section => section.id === sectionid);
      const questionCounter = preview.questions?.findIndex(question => question.id === questionid);
      setCounter({...counter, section: sectionCounter, question: questionCounter ==-1? 0 : questionCounter});
      setNavOpen(!navOpen);
    } else {
      setAlertMessage({ title:"Hold on!", message: "You must complete the required questions before using this menu."});
    }
  }

  const QuestionNavTile = ( { sectionid, question }) => {
    return (
      <Button 
        style={{
          borderBottom:'1px solid black',
          borderRadius:0,
          borderBottomColor:Colours.lightGray,
          padding:10,
          paddingBottom:20,
          backgroundColor:'transparent',
          display:'flex',
          flexDirection:'row',
          alignItems:'center'
        }} 
        onPress={ ()=> questionClickHandler(sectionid, question.id)}
        >

        <QuestionDot 
          key={question.id} 
          question={question}
          responses={responses}
          currentQuestion={currentQuestion}
          />

        <Text 
          lines={1} 
          style={{
            marginLeft:10,
            textAlign:'left',
            marginRight:10,
            fontSize:14,
            fontFamily:'roboto',
            textTransform:'none',
            color: complusoryQuestionsComplete ? Colours.black : Colours.mediumGray
          }}> 
          { question.text } 
        </Text>

      </Button>
    )
  }

  const SectionTitleTile = ( { title, sectionid  }) => {
    return (
      
     <View 
     style={{
      borderBottom:'1px solid black',
      borderBottomColor:Colours.lightGray,
      backgroundColor:'transparent',
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'space-between',
      rowGap:5,
      width: '100%',
    }}
     >

       <Button 
        clear
        style={{
          padding:10
        }}
        onPress={ ()=> questionClickHandler(sectionid)}
        >
        <Text 
          lines={1} 
          bold
          style={{
            marginLeft:10,
            marginRight:10,
            textAlign:'left',
            fontSize:16,
            color: Colours.black,
            fontWeight:'bold',
            fontFamily:'roboto'
          }} 
          > 
          {title ? title : ''} 
          
        </Text>
      </Button>
    
      <Button 
          clear
          style={{
            padding:10,
          }}
          onPress={ ()=> setNavOpen(false)}
          >
        <CancelIcon sx={{color:Colours.red, fontSize:32}}  /> 
      </Button>

     </View>
    )
  }

   useEffect(() => {
    if (!previewSection) return;
    setPreview ({
        sectionid: previewSection.id,
        sectiontitle: previewSection.name,
        questions: questions?.filter( question => question.section === previewSection.id)
      });
  }, [previewSection]);

  if (!navOpen || !preview) return;

  return (

   <View 
    scroll 
    style={{
      display:'flex',
      flexDirection:'column',
      maxHeight:'50vh',
      overflow:'scroll'
      }}>

    <SectionTitleTile 
      sectionid={preview.sectionid} 
      title={preview.sectiontitle}
    />

    {preview.questions?.map(question => (

      <QuestionNavTile 
        key={question.id} 
        sectionid={preview.sectionid} 
        question={question} 
        />

    ))}

    {alertMessage && 
      <Alert 
      title={alertMessage.title}
      message={alertMessage.message}
      onConfirm={() => setAlertMessage()}
      />
    } 

   </View>
   
  );
};

export default QuestionMenu;

