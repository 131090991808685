import React from 'react';
import View from '../../components/UI/View';
import SectionNavTile from '../../components/ReportNav/SectionNavTile';

const SectionMenu = ({ sections, setPreviewSection, previewSection, navOpen, setNavOpen }) => {

  if (!navOpen) return;

  return (

    <View
      style={{
        display:'flex',
        padding:10,
        flexDirection:'row',
        justifyContent: 'space-around',
        }}>
        
     
    {sections?.map(section => (

      <SectionNavTile 
      key={section.id} 
      section={section} 
      previewSection={previewSection} 
      setPreviewSection={setPreviewSection}
      setNavOpen={setNavOpen}
      />

    ))}

      </View>
   
  );
};

export default SectionMenu;
