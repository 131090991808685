import React, {useState, useEffect} from 'react';

import View from '../../components/UI/View';
import RadioButton from '../../components/UI/RadioButton';
import OtherResponse from '../../components/Report/OtherResponse';

const SingleChoice = ({ 
  currentQuestion, 
  handleResponse, 
  defaultResponse,
  defaultAudio,
  otherDetails,
  setOtherDetails,
  scrollIntoView,
  handleUpload
 }) => {

  const [selectedOptions, setSelectedOptions] = useState(defaultResponse?.response);

  const setSelectedOption = ( val ) => {
    if (val === selectedOptions) {
      //clear radio button
      setSelectedOptions('');
    } else {
      setSelectedOptions(val);
    }
  }

  useEffect(() => {
    setSelectedOptions('')
    if (!defaultResponse ) return;
    setSelectedOptions(defaultResponse?.response);
  }, [defaultResponse]);


  useEffect(() => {
    if (!currentQuestion ) return;
    //Answers to multiple choice questions where only one answer is allowed to be captured in a single question column (e.g. gender)
    handleResponse(currentQuestion.id, currentQuestion.text, selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (!currentQuestion) return;
    if (currentQuestion.other) {
      //also record anything they write in the other text input into the response
      //const handleResponse = ( id, column, response ) => {
      handleResponse(
        currentQuestion.id+'otherdetails', 
        currentQuestion.text+' '+currentQuestion.otherlabel+' details',  
        otherDetails
      );
    }
  }, [otherDetails]);

  return (
    
    <View style={{
      paddingLeft:20,
    }}>

        {currentQuestion.answers?.map(answer => (
            <RadioButton 
            key={answer.text} 
            selected={false} 
            label={answer.text}
            audios={answer.audios}
            selectedOption={selectedOptions}
            setSelectedOption ={setSelectedOption}
            />
        ))}

          {currentQuestion.other &&
          <OtherResponse 
            currentQuestion={currentQuestion}
            selectedOption={selectedOptions}
            setSelectedOption ={setSelectedOption}
            otherDetails={otherDetails}
            setOtherDetails = {setOtherDetails}
            scrollIntoView={scrollIntoView}
            handleUpload={handleUpload}
            defaultResponse={defaultResponse}
            defaultAudio={defaultAudio}
            />
        }
       

  </View>

  );
};

export default SingleChoice;
