import React, { useState, useContext, useEffect, useCallback} from 'react';

import ReportScreen from './ReportScreen';
import DashboardScreen from './DashboardScreen';
import LoadingIndicator from '../components/UI/LoadingIndicator';
import View from '../components/UI/View';

const Root = () => {

  const [report, setReport] = useState();
  const [reportID, setReportID] = useState();
  console.log(reportID)

  useEffect(() => {
    setReportID('0')
  }, []);
  

  return (
    <View className={reportID && reportID!=='0' ? 'reportContainer' : 'dashboardContainer'}>

      {!reportID && 
        <LoadingIndicator 
        type='inverse' 
        message='Working ..'
        />
      }

      {reportID==='0'  && 
        <DashboardScreen 
        report={report}
        setReport={setReport}
        setReportID={setReportID}
        reportID={reportID}
        /> 
      }

      {reportID!=='0'  && 
        <ReportScreen 
        report={report}
        setReport={setReport}
        setReportID={setReportID}
        reportID={reportID}
        /> 
      }

    </View>
  );

}

export default Root;