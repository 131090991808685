import React, { useState, useEffect } from 'react';
import View from '../UI/View';
import Button from '../UI/Button';
import LoadingIndicator from '../UI/LoadingIndicator';
import Alert from '../Modals/Alert';
import { Colours } from '../../config';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FilePicker from '../Media/FilePicker';


const FileUpload = ({ currentQuestion, defaultResponse, handleUpload,  }) => {

  const [image, setImage] = useState();
  //console.log(image)
  const [imageLimitReached, setImageLimitReached] = useState(false);
  const [working, setWorking] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(); 
  const [itemToRemove,setItemToRemove] = useState();
   
  //when file is added via the picker it updates the state here so they can show files.
  const addImage = ( uri ) => { 
    if ( !uri ) return;
    const newImages = image ? [...image] : [];
    newImages.push(uri);
    setImage(newImages);
  }

  const removeImage = ( uri ) => { 
    if ( !uri ) return;
    const newImages = image?.filter((img) => img !== uri);
    setImage(newImages);
  }

  const confirmRemove = (item) => {
    setItemToRemove(item);
    setConfirmMessage({ title:"Are you sure?", message: "This action will remove the file from your report."});
  }

  const removeHandler = async (item) => {
    if (!item) return;
    setWorking(true);
    const success = await handleUpload('image', item, currentQuestion, 'delete');
    if (success) removeImage (item); //add to state in question to enable playback of multiple files
    setItemToRemove();
    setWorking(false);
  }

  const openLink = async (url) => {window.open(url, 'callitout');};
  
  //get string from default values and split into array ready to send to the playback list
  useEffect(() => {
    setImage();
    if ( defaultResponse?.image ) {
      const defaultImages = defaultResponse?.image.split(',');
      setImage(defaultImages);
    }
  }, [defaultResponse]);

  //see if we have reached the limit of number of files allowed
  useEffect(() => {
    setImageLimitReached(false);
    if ( !image ) return;
    const limitReached = image?.length >= currentQuestion?.numberfiles;
    setImageLimitReached(limitReached)
  }, [image, currentQuestion]);

  const renderImage = ({item, index}) => {
    if (!item) return;
    return (
      <View 
        style={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          columnGap:15,
          marginBottom: 20,
        }} 
        key={index}>
        { (item.indexOf('.jpg')!==-1 || item.indexOf('.jpeg')!==-1 || item.indexOf('.jpg')!==-1 || item.indexOf('.png')!==-1 || item.indexOf('.gif')!==-1) ? 
         <img
         alt=""
         src={item}
         style={{ width: 100, height: 'auto' }}
         /> :
         <Button 
          borderless
          onPress={()=>openLink(item)}>
          <span style={{
            color:Colours.colour1, 
            textDecoration:'underline'
            }}>
              Uploaded file
            </span>
          </Button>
      }
     
      <Button
        clear
        onPress={() => confirmRemove(item)}
        >
          <RemoveCircleIcon sx={{fontSize:30, color: Colours.red, }}  />
      </Button>
    </View>
    )
  }

  if (working) {
    return (
      <View style={{
        display:'flex',
        flex: 1,
        flexDirection:'row',
        alignItems:'center',
        columnGap:15
      }}>
        <LoadingIndicator size='small' message={'Working'} />
      </View>
    );
  }
  
  return (

    <View>

    <View style={{
        display:'flex',
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        paddingTop:20
      }}>

      {image?.map((item,index) => renderImage ({item, index}) )}

      {!imageLimitReached && 
          <FilePicker
          handleUpload={handleUpload}
          currentQuestion={currentQuestion}
          addFile={addImage}
          type="image"
          />
       } 

    </View>

    {confirmMessage && 
          <Alert 
          title={confirmMessage.title}
          message={confirmMessage.message}
          onConfirm={()=> {removeHandler(itemToRemove); setConfirmMessage();}}
          onCancel={() => { setItemToRemove(); setConfirmMessage();}}
          />
      }

    </View>

  );
};

export default FileUpload;

