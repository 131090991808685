import React, { useState, useEffect } from 'react';

import { db, auth, Colours } from '../../config';
import Button from '../../components/UI/Button';
import View from '../../components/UI/View';
import { collection, query, orderBy, where, onSnapshot, setDoc, Timestamp, doc } from 'firebase/firestore';
import LoadingIndicator from '../../components/UI/LoadingIndicator';

const makeReportID = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

const CallToAction = ({setReport, setReportID}) => {

    const authuser = auth.currentUser;
    const [checkedForReports, setCheckedForReports] = useState(false);
    const [working, setWorking] = useState(false);
    const [incompleteReport, setIncompleteReport] = useState();
    //console.log('incompleteReport ',incompleteReport?.id)

    const getIncompleteReport = () => {
      const report = incompleteReport?.data();
      setReportID(incompleteReport.id);
      setReport(report);
    }

    const startNewReport = async () => {
      //when starting a new report, set any incomplete report to finished
      if (incompleteReport) {
        setWorking(true);
        await setDoc(doc(db, "reports", incompleteReport.id), 
        {
          Finished: true, 
          LastUpdate: Timestamp.fromDate(new Date())
        }, { merge: true });
        setWorking(false);
      }
      setReportID(makeReportID(20));
      setReport();
    }

      useEffect(() => {
        setIncompleteReport();
        //don't worry about checking for incomplete reports for guest users
        if (authuser.isAnonymous) { setCheckedForReports(true); return; }
        //query firestore to get any incomplete reports for signed in user
        const q = query( 
          collection(db, 'reports'), 
          where('User', '==', authuser.uid),
          where('Finished', '==', false),
          orderBy('LastUpdate', 'desc')
        );
        const unsubscribe = onSnapshot(
          q,
          querySnapshot => {
            querySnapshot.size > 0 ? setIncompleteReport(querySnapshot.docs[0]) : setIncompleteReport();
            setCheckedForReports(true);
          },
          error => {
            console.log(error.message);
          }
        );
        return () => {
          //console.log('Detaching the database listener');
          unsubscribe();
          setIncompleteReport();
          setCheckedForReports(false);
        };
      }, [authuser]);


      if (!checkedForReports || working) return ( 
        <View className='container'>
            <LoadingIndicator/>
        </View>
    )

    
    return (

      <View style={{
        display:'flex',
        flexDirection:'column'
      }}>

        {incompleteReport && 
            <Button
                primary
                style={{marginTop:10}}
                onPress={  getIncompleteReport }
                >Continue previous report
            </Button>
        }

        {checkedForReports && <Button
            primary
            style={{marginTop:10, marginBottom:30}}
            onPress={ startNewReport }
            >
               { incompleteReport ? 'Start a new report' : 'Tap here to Call it out'}
        </Button>}

        </View>

     
       
  );
};

export default CallToAction;




