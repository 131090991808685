import React, { useState, useEffect, useContext } from 'react';

import Button from '../components/UI/Button';

import View from '../components/UI/View';
import Image from '../components/UI/Image';
import Background from '../components/App/Background';
import Heading from '../components/UI/Heading';
import Introduction from '../components/App/Introduction';
import CallToAction from '../components/App/CallToAction';
import TopMenu from '../components/Navigation/TopMenu';
import suncorner from '../assets/images/suncorner.png';

import { Colours, QuestionIDs, auth, db } from '../config';
import { UserContext } from '../store/user-context'

import { getDoc, doc,  } from 'firebase/firestore';

const DashboardScreen = ({report, reportID, setReport, setReportID}) => {
  const authuser = auth.currentUser;
  const [welcomeHeading, setWelcomeHeading] = useState(`Hi there`);
  const logoImage = require('../assets/images/logo.png');
  const bgImage = require("../assets/images/bg2-b.png");
  const UserCtx = useContext(UserContext);
  const nameQuestionID = QuestionIDs?.find(id => id.name === 'name')?.id;

    //get user data from database 
    const getUserName = async () => {
      if (authuser.isAnonymous) {
        setWelcomeHeading(`Hi, Guest`);
      } else if (UserCtx.user) {
        setWelcomeHeading(`Hi, ${UserCtx.user[nameQuestionID]}`);
      } else {
        const docRef = doc(db, "users", authuser.uid);
        const nameQuestionID = QuestionIDs?.find(id => id.name === 'name')?.id;
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data()[nameQuestionID]) setWelcomeHeading(`Hi, ${docSnap.data()[nameQuestionID]}`);
        }
      }
    }

    useEffect(() => {
      if (!authuser || !UserCtx) return;
      getUserName();
    }, [authuser, UserCtx]);

  return (
    <>

      <TopMenu page='dashboard' setReportID={setReportID} />

      <Background/>

      {/*<View className='settings'>
        <Button 
        onPress={() => navigation.openDrawer()}
        clear 
        >
          
        <SettingsIcon size={40} color={Colours.colour1}  />
      </Button>
        </View>*/}
  
      {/*If they update this field in a report, or when they update their profile in the app, the updated name will appear here.*/}
      <Heading centred>
        { welcomeHeading }
      </Heading>

      <Introduction/>

      <CallToAction 
      setReport={setReport}
      setReportID={setReportID}
      />

      </>
  );
};

export default DashboardScreen;




