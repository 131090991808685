import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, linkWithCredential, updateEmail, deleteUser } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// add firebase config
const firebaseConfig = {
  apiKey: "AIzaSyAxfhoyWYJ-b477NR9Js36-ONUh4aII7Ww",
  authDomain: "call-it-out-app.firebaseapp.com",
  projectId: "call-it-out-app",
  storageBucket: "call-it-out-app.appspot.com",
  messagingSenderId: "1082570160961",
  appId: "1:1082570160961:web:7a1605cb9f127de5d17fc0",
  measurementId: "G-B2YGL2GGNJ"
};

// initialize firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
export const authEmail = EmailAuthProvider;
export const linkCredential =  linkWithCredential;
export const updateFBEmail =  updateEmail;
export const deleteAccount =  deleteUser;
