import React, {useState, useEffect} from 'react';
import View from '../../components/UI/View';
import NavTile from '../../components/ReportNav/NavTile';
import ProgressBar from '../../components/ReportNav/ProgressBar';
import CurrentSectionNavTile from '../../components/ReportNav/CurrentSectionNavTile';
import Alert from '../../components/Modals/Alert';

import FloatingMenu from '../../components/ReportNav/FloatingMenu';
import { Colours } from '../../config';

const Nav = ({
  sections, 
  currentSection, 
  setCurrentSection,
  questions, 
  counter, 
  sectionQuestions,
  currentQuestion, 
  setCounter, 
  saveHandler, 
  responses,
  complusoryQuestionsComplete,
  confirmExit
}) => {

  const [navOpen, setNavOpen] = useState(false);
  const [previewSection, setPreviewSection] = useState(currentSection);

  const [firstQuestion, setFirstQuestion] = useState(true);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [working, setWorking] = useState(false);

  const [nextInActive, setNextInactive] = useState(false); //disable next button for mandatory questions
  //console.log('nextInActive',nextInActive);
  const [progress, setProgress] = useState('0');

  const [alertMessage, setAlertMessage] = useState();

/*   //confirm exit of report after hitting Home or Submit button
  const submitReport = () => {
    const alertButtons = [
      {text: 'Cancel', style: 'cancel'},
      {text: `I'm done. Submit report.`, onPress: async () => {
        setWorking(true);
        await saveHandler('finish');
        setWorking(false);
        setShowThanks(true);
      }}
    ];
    //allow save for later option for logged in users, don't have this option if we are on the last question
    if (!authuser.isAnonymous && !lastQuestion) alertButtons.push({text: `I might come back to it later.`, onPress: async () => {
      setWorking(true);
      await saveHandler(); 
      setWorking(false);
      setReportID('0');
    }})

    setAlertMessage({ title:"Report submission", message: "You are about to finish and submit your report. After submitting you won't be able to make any changes without contacting us."});

  }*/

  const changeQuestion = (dir) => {
    
    if (!counter || !sectionQuestions) return;
    
    //console.log('question',counter.question,'of',sectionQuestions.length,'section',counter.section,'of',sections.length); 
    
    const newCounter = {...counter};

    if ( dir === 'next') {
      //if next is not enabled then do not pregress
      if (nextInActive) { 
        setAlertMessage({ title:"Response required", message: "Please complete this question before continuing."});
        return }
      if ( (counter.question+1) < sectionQuestions.length ) {
        //go to next question
        newCounter.question = counter.question+1;
      } else {
        //go to next section if there is one
        if ((counter.section+1) < sections.length) {
          newCounter.question = 0;
          newCounter.section = counter.section+1;
        } else {
          //no new section
        }
      }
    } else {
      //back
      if (counter.question === 0) {
        //go back to previous section if there is one
        if (counter.section === 0 ) {
          //no previous section
        } else {
          const secQuestions = questions.filter( question => question.section === sections[counter.section-1].id);
          newCounter.question = secQuestions.length-1;
          newCounter.section = counter.section-1;
        }
      } else {
        //decrement question number
        newCounter.question = counter.question-1;
      }
    }
    setCounter({...counter, section: newCounter.section, question: newCounter.question});
    setNavOpen(false);
    saveHandler();
  }

  const openNavigation = () => {
    setPreviewSection(currentSection);
    setNavOpen(!navOpen);
  }

  const calculateProgress = () => {
    let completedResponsesCounter=0;
      //go through questions array and see whether a response matches the question id and if this response is valid
      questions.map ( question => { 
        const questionResponse = responses.find(response => (
          response.id?.indexOf(question.id)!==-1 && 
          response.response!==false && 
          response.response!==""
          ));
          //if we've found a valid response then up the counter
        if (questionResponse) completedResponsesCounter++;
      });
      //calculate the percentage completed based on how many questions they've answered and the total number of questions
      let percentageCompleted = ( completedResponsesCounter / questions.length ) * 100;
      //don't show 0 - if it's the first question make sure the progress bar will be visible
      if ( percentageCompleted === 0 ) percentageCompleted = 10;
      //convert this to a % string to pass to the progress bar component (width style)
      const percentageCompletedStr = percentageCompleted + '%';
      setProgress(percentageCompletedStr)
  }

  const setNextButtonState = () => {
    setNextInactive(false);
    if (!responses || !currentQuestion || !questions) return;
    if (currentQuestion.mandatory) {
      if (currentQuestion.type === 'Multiple choice') {
        //get responses that are checked, if no responses then disable the next button
        const checkedResponses = responses.filter( response => response.response !=='' && response.id?.indexOf(currentQuestion.id) !== -1)
        if (checkedResponses?.length === 0) { setNextInactive(true); }
      } else {
        const currentResponse = responses.find( response => response.id?.indexOf(currentQuestion.id) !== -1 );
        if (!currentResponse || currentResponse?.response==="") { setNextInactive(true); }
      }
    } 
  }

  useEffect(() => {
    if (!counter || !sectionQuestions || !sections) return;
    setFirstQuestion( counter.question === 0 && counter.section === 0  ? true : false );
    setLastQuestion( (counter.question+1) === sectionQuestions.length && (counter.section+1) === sections.length ? true : false );
  }, [counter, sectionQuestions, sections]);

    
    useEffect(() => {
      //update whether next button is enabled depending on whether the questio is mandatory and their responses
      setNextButtonState();
      //get progress depending on their reponses and how many questions there are in total
      calculateProgress();
    }, [responses, currentQuestion, questions]);



  return (

    <View 
      style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end',
        height: navOpen ? '100%': 'auto',
        position: navOpen ? 'absolute': 'inherit',
        backgroundColor: navOpen ? Colours.lightBlue: 'none',
        overflow:'hidden',
        width:'100%',
        maxWidth:'100%'
      }}>

      {navOpen && 
      //spacer to click to close nav
      <View onclick={()=>setNavOpen(false)} style={{ flexGrow:1}} />
      }

     <FloatingMenu 
        navOpen={navOpen} 
        questions={questions}
        sections={sections}
        previewSection={previewSection} 
        setCurrentSection={setCurrentSection}
        setPreviewSection={setPreviewSection}
        setNavOpen={setNavOpen}
        currentQuestion={currentQuestion}
        counter={counter}
        setCounter={setCounter}
        responses={responses}
        complusoryQuestionsComplete={complusoryQuestionsComplete}
        />
      
       <ProgressBar 
        progress={progress}
       />

        <View style={{
          display:'flex',
          flexDirection:'row',
          justifyContent: 'space-between',
          alignItems:'center',
          padding:10,
          paddingTop:10,
          paddingBottom:30,
          backgroundColor:Colours.colour2, 
          flex:1      
        }}>

         <View style={{
           display:'flex',
           flexDirection:'row',
           alignItems:'center',
           columnGap:5,
           justifyContent: 'flex-start',
           width:'30%',
           columnGap:'10%'
          }}>
            
            {complusoryQuestionsComplete && 
            <NavTile 
              type= 'finish'
              onPress={()=>confirmExit("You are about to finish and submit your report. After submitting you won't be able to make any changes without contacting us.")}
              navOpen={navOpen}
              working={working}
              />
              }
            
            <NavTile 
              type= { firstQuestion ? 'blank' : 'back'}
              onPress={()=>changeQuestion('back')}
              navOpen={navOpen}
              />
            
            </View>


         <CurrentSectionNavTile
            section={currentSection} 
            counter={counter}
            sectionQuestions={sectionQuestions}
            onPress={openNavigation}
            currentQuestion={currentQuestion}
            navOpen={navOpen}
            responses={ responses}
          />

          <View style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent: 'flex-end',
            width:'30%',
          }}>

          <NavTile 
            type= { lastQuestion ? 'submit' : nextInActive ? 'disabled' : 'next'}
            onPress={ () => lastQuestion ? confirmExit("You are about to finish and submit your report. After submitting you won't be able to make any changes without contacting us.") : changeQuestion('next')}
            navOpen={navOpen}
            working={working}
            />

            </View>

        </View>

       <View className='trimImage'/>
     
      {alertMessage && 
          <Alert 
          title={alertMessage.title}
          message={alertMessage.message}
          onConfirm={() => setAlertMessage()}
          />
        }
    </View>

  );
};

export default Nav;

