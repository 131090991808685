import React from 'react';
import classes from './View.module.css';

const View = ({ style, scroll, className, children, onclick }) => {


  return (
    <div 
    onClick ={onclick}
    className={`
        ${classes[className]} 
        ${scroll && classes.scroll}
        `}
      style={style}
      >
      
      {children}
    
    </div>
    );
};

export default View;
