
import View from '../../components/UI/View';
import { Colours } from '../../config';

const QuestionDot = ( { question, responses, currentQuestion } ) => {
    //see if there is a response (turn the dot green)
    const questionResponse = responses.find(response => (
     response.id?.indexOf(question.id)!==-1 && 
     response.response!==false && 
     response.response!==""
     ));
     //see whether this is for the quetion we're in (turns the dot orange)
     const current = currentQuestion?.id === question.id;
    return (
       <View 
         style={{
          width:7,
          height:7,
          borderRadius:3,
          backgroundColor: questionResponse ? Colours.lightGreen : current ? Colours.colour1 : Colours.lightGray,
         }}>
         </View>
     )
};

export default QuestionDot;
