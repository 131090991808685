import React, { useState, useEffect, useRef } from 'react';
import View from '../../components/UI/View';
import Text from '../../components/UI/Text';
import Button from '../../components/UI/Button';
import ButtonRow from '../../components/UI/ButtonRow';
import MediaIcon  from '../../components/Media/MediaIcon';
import LoadingIndicator from '../../components/UI/LoadingIndicator';
import { Colours } from '../../config';
import Modal from "../../components/Modals/Modal";
import Alert from "../../components/Modals/Alert";

const FilePicker = ({ style, handleUpload, currentQuestion, addFile, type }) => {

  const [pickedFile, setPickedFile] = useState();
  const [documentPreview, setDocumentPreview] = useState(null);
  const [working, setWorking] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [optionsModalVisible, setOptionsModalVisible] = useState(false);
  const formRef= useRef();

  const submitHandler = async () => {
    setWorking(true);
    setOptionsModalVisible(!optionsModalVisible);
    const uploadedFile = await handleUpload(type,pickedFile, currentQuestion, 'add');
    if (uploadedFile) addFile (uploadedFile); //add to state in question to enable playback of multiple files
    setWorking(false);
    setPickedFile();
  }

  const cancelHandler = () => {
    setOptionsModalVisible(!optionsModalVisible);
    setPickedFile();
  }

  //called when file is chosen
  const pickFileHandler = async ( event ) => {
    const file = event.target.files[0];
    //console.log('size',(file.size / 1024 / 1024),currentQuestion.limitfile);
    if (file) {
      //check file size
      if (currentQuestion.limitfile) {
        if (!file?.size) {
          setAlertMessage({ title:"File size error", message: 'Can`t select this file as the size is unknown.'});
          return false;
        }
        if ((file.size / 1024 / 1024) > currentQuestion.limitfile) {
          setAlertMessage({ title:"Too big!", message: `File must be smaller than ${currentQuestion.limitfile}MB`});
          return false;
        }
      }
      setPickedFile(file);
    }
  };

  useEffect(() => {
    if (pickedFile) {
      setDocumentPreview(
        <MediaIcon
        placeholder={pickedFile.name ? pickedFile.name : 'Document selected'}
        icon={'document'}
        colour={ Colours.colour1}
        size={ 100}
        />
      )
    } else {
      setDocumentPreview();
    }
  }, [pickedFile]);


  if (working) {
    return (
        <LoadingIndicator size='small' message={'Uploading'} />
    );
  }

  return (
    <>
    
    <Button
      clear
      onPress={() => setOptionsModalVisible(!optionsModalVisible)}
      style={style}>

      <MediaIcon
        placeholder={ type==="audio" ? 'Upload audio': 'Upload file'}
        icon='upload'
        colour={Colours.colour1}
        />

    </Button>

    {optionsModalVisible && 

      <Modal 
        back 
        title='Upload a file' 
        onCancel={() => setOptionsModalVisible(!optionsModalVisible)}
        >
          
          <View style={{
            justifyContent: 'center',
            flex:1,
            display: 'flex',
            flexDirection: 'column',
            alignItems:'center',
            rowGap:50,
            width:'100%'
          }}>

          <View style={{ display:'flex', flexDirection:'column', alignItems:'center'}}>

          { documentPreview }

          {!documentPreview && 

          <Button clear>
            <input 
              id="file-upload" 
              style={{display: 'none'}} 
              ref={formRef} 
              type="file" 
              onChange={pickFileHandler} 
            />
            <label 
            for="file-upload" 
            style={{
              border: '1px solid var(--colour1)',
              backgroundColor: Colours.colour1,
              color: Colours.white,
              padding: '10px 20px',
              cursor: 'pointer',
              }}>
              Choose file
            </label>
        </Button>     

          }

          {!documentPreview && currentQuestion?.limitfile &&
          <Text style={{
            color: Colours.colour1,
            fontSize: 10,
            marginTop:30
          }}>
            Max {currentQuestion.limitfile} MB
          </Text>}

          {alertMessage && 
            <Alert 
            title={alertMessage.title}
            message={alertMessage.message}
            onConfirm={() => {
              if (formRef.current) formRef.current.value = null;
              setAlertMessage()
            }}
            />
          } 

          </View>

        <ButtonRow 
          submitLabel="Upload" 
          handleSubmit={ pickedFile ? submitHandler : false}
          cancelSubmit={cancelHandler}
          />
         
         </View>
    
    </Modal>
    
    }
     
    </>
  );
};

export default FilePicker;
