import React from 'react';

import Error from './Error';
import Root from './Root';
import Share from '../components/App/PublicShare';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  { path: '/', element: <Root/>, errorElement: <Error />},
  { path: '/share/:reportId', title: 'Call It Out Report', element: <Share/> , errorElement: <Error />},
])

function Launch() {
  return (
   <RouterProvider router={router }/>
  );
}

export default Launch;
