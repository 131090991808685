import React, {useState, useEffect, useContext, useRef} from 'react';

import { collection, updateDoc, doc, setDoc, getDocs, query, orderBy, Timestamp, getDoc } from 'firebase/firestore';
import { db, Colours, auth, QuestionIDs, SectionIDs} from '../config';

import Alert from '../components/Modals/Alert';
import Button from '../components/UI/Button';
import Background from '../components/Report/Background';
import View from '../components/UI/View';
import LoadingIndicator from '../components/UI/LoadingIndicator';
import ConfirmPersonal from '../components/Report/ConfirmPersonal';
import TopMenu from '../components/Navigation/TopMenu';
import Thanks from '../components/Modals/Thanks';
import { uploadAudioFile, deleteAudioFile } from '../components/Media/audioFunctions';
import { uploadImageFile, deleteImageFile } from '../components/Media/imageFunctions';

import QuestionTitle from '../components/Report/QuestionTitle';
import SectionTitle from '../components/Report/SectionTitle';
import QuestionForm from '../components/Report/QuestionForm';
import { UserContext } from '../store/user-context'

import campfireImage from '../assets/images/bluecampfire.png';

import Nav from '../components/ReportNav/Nav';

const aboutSectionID = SectionIDs?.find(id => id.name === 'about')?.id;
const consentQuestionID = QuestionIDs?.find(id => id.name === 'consent')?.id;
const locationQuestionID = QuestionIDs?.find(id => id.name === 'location')?.id;

const ReportScreen = ({ report, reportID, setReport, setReportID }) => {

  const [questions, setQuestions] = useState();
  const [sections, setSections] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentSection, setCurrentSection] = useState();
  const [sectionQuestions, setSectionQuestions] = useState();
  const [counter, setCounter] = useState();

  const authuser = auth.currentUser;
  const UserCtx = useContext(UserContext);
  const [responses, setResponses] = useState([]);
  const [complusoryQuestionsComplete, setComplusoryQuestionsComplete] = useState(false);
  const scrollRef = useRef();
  const [personalDetails, setPersonalDetails] = useState();

  const [alertMessage, setAlertMessage] = useState();
  const [showThanksModal, setShowThanksModal] = useState(false);

  const scrollIntoView =() => {
   // console.log('scrollIntoView');
   // if (scrollRef) scrollRef.current?.scrollTo({x: 0, y: -1000, animated: true})
  }

  const closeReport = () => {
    setShowThanksModal(false);
    setReportID('0');
  }

  const alertHandler = async (action) => {
    if (action === 'finish') {
      //submit report
      await saveHandler('finish');
      setShowThanksModal(true);
    } else if (action ==='save'){
      //come back later
      await saveHandler(); 
      closeReport();
    }
    setAlertMessage();
  }

  const renderAlert = () => {
    //allow save for later option for logged in users and if it's not the last question
    const lastQuestion = (counter.question+1) === sectionQuestions.length && (counter.section+1) === sections.length ? true : false ;
    const onThird = (!authuser.isAnonymous && !lastQuestion ) ? () => alertHandler('save') : false; 
    return (
      <Alert 
      title={alertMessage.title}
      message={alertMessage.message}
      onCancel={() => alertHandler('cancel')}
      onConfirm={() => alertHandler('finish')}
      submitLabel={`I'm done. Submit report`}
      onThird={onThird}
      thirdLabel={`I might come back to it later.`}
      />
    )
  }

  const confirmExit = (msg) => {
    //if they have answered compulsory questions then create alert before saving, otherwise just go to home page
    const m = msg ? msg : "You haven't submitted your report yet. What would you like to do?";
    complusoryQuestionsComplete ? 
      setAlertMessage({ title:"Report submission", message: m}) : 
      closeReport();
  }

  //console.log(currentQuestion)
  //console.log('responses',responses);
  //console.log(counter)
  //console.log('report',report)
  //console.log('reportID', reportID);

  const processIncompleteReport = () => {
    const responseData = [];
    for (const [key, value] of Object.entries(report)) {
      if (
        key!=='User' && 
        key !== "Finished" && 
        key !== 'CreatedAt' && 
        key !== 'LastUpdate' && 
        key !== 'LastUpdateString' && 
        key !== 'LastSectionVisited' && 
        key !== 'LastQuestionVisited' 
        ) {
        const responseObj = {};
        //replaced escaped Code for comma
        const adjustedKey = key?.replaceAll("%2C", ",");
        const adjustedValue = value?.replaceAll("%2C", ",");
        responseObj.column = adjustedKey;
        responseObj.response = adjustedValue;
        //get response id from question data
        let responseID = null;
        //look through questions to see where 
        questions.forEach( question => {
          if (question.text === adjustedKey) {
            //key matches question text for short and long answer and single choice
            responseID = question.id;
          } else if (question.other && (adjustedKey === question.text+' '+question.otherlabel)) {
            //check for other (checkbox)
            responseID = question.id+'other';
          } else if (question.other && (adjustedKey === question.text+' '+question.otherlabel+' details')) {
            //check for other (text input)
            responseID = question.id+'otherdetails';
          } else if (question.other && (adjustedKey === question.text+' '+question.otherlabel+' audio')) {
            //check for other (audio input)
            responseID = question.id+'otheraudio';
          } else if (adjustedKey === question.text+" audio") {
            //check for audio response
            responseID = question.id+" audio";
          } else if (adjustedKey === question.text+" image") {
            //check for image response
            responseID = question.id+" image";
          } else if (adjustedKey === question.text+" suburb") {
            //check for location - suburb
            responseID = question.id+" suburb";
          } else if (adjustedKey === question.text+" state") {
            //check for location - state
            responseID = question.id+" state";
          } else if (adjustedKey === question.text+" postcode") {
            //check for location - postcode
            responseID = question.id+" postcode";
          } else if (adjustedKey === question.text+" suburb") {
            //check for location - suburb
            responseID = question.id+" suburb";
          } else if (question.type==="Multiple choice") {
            //console.log(key,value)
            //for multiple choice questions we need to loop through the question answers
            question.answers?.map ( (answer, index) => {
              if (adjustedKey === answer.text) {
                responseID = question.id+index;
              }
            });
          } else {
            //console.log(question.type,question.text,key) 
          }
        });
        responseObj.id = responseID;
        //console.log(responseObj)
        responseData.push(responseObj)
      }
    }
    setResponses(responseData)
  }

  //upload handler is called when upload is selected from audio recorder
  const handleUpload = async (type, pickedFile, currentQuestion, method) => {
    //upload asset to firebase storage in the 'users' directory
    const filepath = `users/${authuser.uid}`;
    if (type==='audio'){
      //get question id for when we are looking through the responses array
      const audioQuestionID = currentQuestion.other ? currentQuestion.id+' '+currentQuestion.otherlabel+' audio' : currentQuestion.id+" audio"
      const audioQuestionText = currentQuestion.other ? currentQuestion.text+' '+currentQuestion.otherlabel+' audio' : currentQuestion.text+" audio"
      if (method === 'delete') {
        //delete audio from server
        try {
          await deleteAudioFile(pickedFile);
          //once deleted, update the responses array 
          const existingResponse = responses.find(resp => resp.id === audioQuestionID);
          if (existingResponse) {
            //if there is an existing response (there should be) create an array out of the string, remove the element and then convert back to string
            const adjustedExistingResponse = existingResponse.response?.replaceAll("%2C", ",");
            const existingResponsesArray = adjustedExistingResponse.split(',');
            const newResponseArray = existingResponsesArray?.filter((resp) => resp !== pickedFile);
            const newResponseStr = newResponseArray?.toString();
            handleResponse( 
              audioQuestionID, 
              audioQuestionText, 
              newResponseStr);
              return true;
          } else {
            console.log('no existing response found');
            return false;
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      } else {
        //add audio
        try {
          const uploadAssetUrl = await uploadAudioFile(pickedFile, filepath);
          if (uploadAssetUrl) {
            //once uploaded, add the firebase URL to the responses array. separate multiple responses by a comma
            const existingAudios = responses.find(resp => resp.id === audioQuestionID);
            const response = (existingAudios) ? existingAudios.response + ',' + uploadAssetUrl : uploadAssetUrl ;
            handleResponse( 
              audioQuestionID, 
              audioQuestionText, 
              response);
            return (uploadAssetUrl);
          } else {
            return false;
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      }
    } else if (type === 'image') {
      if (method === 'delete') {
        //delete image from server
        try {
          await deleteImageFile(pickedFile);
          //once deleted, update the responses array 
          const existingResponse = responses.find(resp => resp.id === currentQuestion.id+" image");
          if (existingResponse) {
            //if there is an existing response (there should be) create an array out of the string, remove the element and then convert back to string
            const existingResponsesArray = existingResponse.response?.split(',');
            const newResponseArray = existingResponsesArray?.filter((resp) => resp !== pickedFile);
            const newResponseStr = newResponseArray?.toString();
            handleResponse( 
              currentQuestion.id+" image", 
              currentQuestion.text+" image", 
              newResponseStr);
              return true;
          } else {
            console.log('no existing response found');
            return false;
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      } else {
        //add image
        try {
          const uploadAssetUrl = await uploadImageFile(pickedFile, filepath);
          console.log('uploadAssetUrl',uploadAssetUrl)
          if (uploadAssetUrl) {
            //once uploaded, add the firebase URL to the responses array. separate multiple responses by a comma
            const existingImages = responses.find(resp => resp.id === currentQuestion.id+" image");
            const response = (existingImages) ? existingImages.response + ',' + uploadAssetUrl : uploadAssetUrl ;
            handleResponse( 
              currentQuestion.id+" image", 
              currentQuestion.text+" image", 
              response);
            return (uploadAssetUrl);
          } else {
            return false;
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      }
    } else {
      return false;
    }
  };



  //stores responses array in state
  const handleResponse = ( id, column, response ) => {
    if (!currentQuestion) return;
    //console.log('handleResponse',column, response);
    //make a copy of current responses
    const updatedResponses = [...responses];
    //create new or updated response from the params
    const updatedResponse = { 
      id: id,
      column: column, 
      response: response 
    }
    //see whether we are updating or creating a new response
    const responseIndex = responses.findIndex(resp => resp.id === id);
    if (responseIndex>-1) {
      //update previous response
      updatedResponses[responseIndex] = updatedResponse;
    } else {
      //new response
      updatedResponses.push(updatedResponse)
    }
    //console.log('update response',updatedResponse);
    setResponses(updatedResponses);
};
  const handleMultipleResponses = ( multipleResponses ) => {
    //console.log('handleMultipleResponses',multipleResponses);
    if (!currentQuestion ) return;
    //delete current question responses
    const updatedResponses = responses.filter (resp => resp.id?.indexOf(currentQuestion.id) === -1);
    updatedResponses.push(...multipleResponses);
    setResponses(updatedResponses);
};

//get user data from database (to prefill about you section of the form)
const getUserData = async () => {
  setPersonalDetails();
  const docRef = doc(db, "users", authuser.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && !authuser.isAnonymous) {
    //set user context
    const userData = docSnap.data();
    UserCtx.setCurrentUser(userData);
    //see if there are answers to all the personal questions so they can skip the first bit
    const personalQuestions = [];
    let allPersonalQuestionsAnswered = true;
    const personalQuestionIDs = questions?.filter(question => question.section === aboutSectionID);
    personalQuestionIDs?.map(question => {
      //ignore consent question
      if (question.id !== consentQuestionID) {
        if (userData[question.id] && userData[question.id]!=='' && question.id !== locationQuestionID) {
          //if the user data question matches the question id then use this
          personalQuestions.push( {
            id: question.id, 
            column: question.text,
            response: userData[question.id]
          })
        } else if (question.id === locationQuestionID) {
          const suburb = userData[question.id + " suburb"] ? userData[question.id + " suburb"] : '';
          const state = userData[question.id + " state"] ? userData[question.id + " state"] : '';
          const postcode = userData[question.id + " postcode"] ? userData[question.id + " postcode"] : '';
          const textResponse = `${suburb ? suburb+' ' : ''}${state ? state+' ' : ''}${postcode ? postcode :''}`; 
          personalQuestions.push( 
            { 
              id: question.id,  
              column: question.text, 
              response: textResponse
            }, 
            {
              id: question.id+" suburb",   
              column: question.text+" suburb", 
              response: suburb 
            }, 
            {
              id: question.id+" state",   
              column: question.text+" state", 
              response: state
            }, 
            {
              id: question.id+" postcode",   
              column: question.text+" postcode", 
              response: postcode 
            }, 
          )
        } else {
          //if this is not the non compulsory location question and there is no answer then they haven't finished all the personal questions
          allPersonalQuestionsAnswered = false;
        }
      }
    });
      allPersonalQuestionsAnswered ? setPersonalDetails(personalQuestions) : setPersonalDetails([])
  } else {
    setPersonalDetails([])
  }
}

const prepareColumnName = (name) => {
  const adjustedName = name ? name.replaceAll(",", "%2C") : name;
  return (adjustedName);
}

const saveHandler = async ( type ) => {
  //get the response/s for the current question from the response state - use IndexOf because response ids to muitple choice questions are saved as questionid+0 etc. response array can hold multiple responses for this question
  const responseArray = responses.filter ( response => response.id?.indexOf(currentQuestion.id) !==-1 );
  responseArray.map( async (response) => {
    //if they are in the about section save this info to their user, so that the next time they make a report these fields will be auto populated. Do not prepopulate the consent checkbox or for guests.
    if (currentSection?.id === aboutSectionID && currentQuestion?.id!==consentQuestionID) {
      try {
        const userRef = doc(db, "users", authuser.uid);
        const resp =  authuser.isAnonymous ? '' : response.response ? response.response : '';
        await updateDoc(userRef, {
          [response.id]: resp 
        });
      } catch (error) {
        const msg = 'Error saving user settings '+error.message;
        alert(msg);
      }
    }
  })

  // don't update reports collection if they haven't completed all the mandatory questions
  if (complusoryQuestionsComplete) {
    //set up document to write to the DB
    const updatedDoc = {};
    let docSnap;
    //if this is the first time they are writing to the database then save all the mandatory questions they completed
    try {
      const docRef = doc(db, "reports", reportID);
      docSnap = await getDoc(docRef);
    } catch (error) {
      const msg = 'Error saving mandatory questions '+error.message;
      alert(msg);
    }
    if ( docSnap && !docSnap.exists() ){
      if (authuser.uid) { 
        updatedDoc.User = authuser.uid;
        updatedDoc.CreatedAt = new Date().toLocaleString('en-GB'); 
      }
      //get the responses of the mandatory questions - doesn't cater for audio or image responses
      //also add location question becuase it is no long er mandatory
      const mandatoryQuestions = questions.filter( question => ( question.mandatory || question.id===locationQuestionID ));
      const mandatoryResponses = responses.filter( response => {
        let questionAnswered = false;
        mandatoryQuestions.forEach( question => { 
            if ( 
              response.id === question.id || 
              (response.id.indexOf(question.id)!==-1)
              ) questionAnswered = true; 
          })
          return questionAnswered
      } );
      //console.log(mandatoryResponses.length);
      //add these responses to the document we will send to the database
      mandatoryResponses.forEach(response => {
        //remove commas etc from column name to avoid CSV errors
        const columnName = prepareColumnName(response.column)
        const responseClean = response.response ? prepareColumnName(response.response) : ''
        //console.log(columnName, response.response);
        updatedDoc[columnName] = responseClean;
      });
    }
    //get the response/s for the current question from the response state - use IndexOf because response ids to muitple choice questions are saved as questionid+0 etc. response array can hold multiple responses for this question
    const responseArray = responses.filter ( response => response.id?.indexOf(currentQuestion.id) !==-1 );
    responseArray.map( async (response) => {
      if (response.response) {
        //remove commas etc from column name to avoid CSV errors
        const columnName = prepareColumnName(response.column)
        const responseClean = response.response ? prepareColumnName(response.response) : ''
        //console.log(columnName, responseClean);
        updatedDoc[columnName] = responseClean;
        updatedDoc.Finished = type==='finish' ? true : false;
        updatedDoc.LastUpdateString = new Date().toLocaleString('en-GB');
        updatedDoc.LastUpdate = Timestamp.fromDate(new Date());
        updatedDoc.LastSectionVisited = counter.section+1;
        updatedDoc.LastQuestionVisited = counter.question+1;
        //console.log('saving to db report', reportID, updatedDoc);
        //use setDoc to add / update report in database
        try {
          await setDoc(doc(db, "reports", reportID), updatedDoc, { merge: true });
        } catch (error) {
          const msg = 'Error saving report '+reportID+" "+error.message;
          alert(msg);
        }
        }
    })
    if (type==='finish') {
        updatedDoc.Finished = true;
        updatedDoc.LastUpdateString = new Date().toLocaleString('en-GB');
        updatedDoc.LastUpdate = Timestamp.fromDate(new Date());
        console.log('finishing report', reportID);
        try {
          await setDoc(doc(db, "reports", reportID), updatedDoc, { merge: true });
        } catch (error) {
          const msg = 'Error finishing report '+reportID+" "+error.message;
          alert(msg);
        }
    }
  }
}

const getFormData = async (  ) => {
  const questionsArray = [];
  const sectionsArray = [];
  const querySnapshot1 = await getDocs(query(collection(db, "questions"), orderBy("order")));
  querySnapshot1.forEach((doc) => {
    questionsArray.push(doc.data());
  });
  const querySnapshot2 = await getDocs(query(collection(db, "sections"), orderBy("order")));
  querySnapshot2.forEach((doc) => {
    sectionsArray.push(doc.data());
  });
  setQuestions(questionsArray);
  setSections(sectionsArray);
   //set counter
   if (report && report.LastSectionVisited && report.LastQuestionVisited) {
    setCounter({...counter, section: (report.LastSectionVisited-1), question:(report.LastQuestionVisited-1)});
  } else {
    setCounter({...counter, section: 0, question:0});  
  }
}
//get responses for previously saved forms
useEffect(() => {
  (report && questions) ? processIncompleteReport() : setResponses([]);
}, [questions]);

//get form data from firebase after component has initially loaded
useEffect(() => {
  getFormData();
}, []);

//update current question and section if counter changes
useEffect(() => {
  if (!counter || !sections || !questions) return;
  if (scrollRef) {
    scrollRef.current?.scroll({
      top: 0,
      behavior: "smooth"
    });
  }
  /*window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  if (scrollRef) scrollRef.current?.scrollTo({x: 0, y: 10000, animated: true})*/
  const curSection = sections[counter.section];
  
  setCurrentSection(curSection);
  const secQuestions = questions.filter( question => question.section === curSection.id);
  if (secQuestions) {
    setCurrentQuestion(secQuestions[counter.question]);
    setSectionQuestions(secQuestions);
  }
}, [counter]);


useEffect(() => {
  if (!questions) return;
  //don't write to DB if not all compulsory questions have been answered
  const mandatoryQuestions = questions.filter( question => question.mandatory);
  const mandatoryQuestionsAnswered = mandatoryQuestions.filter( question => {
    let questionAnswered = false;
    responses?.forEach( response => { 
      if ( 
        response.id === question.id || 
        (response.id?.indexOf(question.id)!==-1 && response.response !=='')) 
          questionAnswered = true;
    })
    return (questionAnswered)
  } );
  setComplusoryQuestionsComplete(mandatoryQuestionsAnswered?.length >= mandatoryQuestions?.length);

}, [responses]);


useEffect(() => {
if (!authuser || !UserCtx || !questions) return;
getUserData();
}, [authuser, reportID, questions]);


  if (!currentQuestion || !counter || !personalDetails) { 
    return <LoadingIndicator type='secondary' />;
  }

  //for new reports if they are a registered user and they have answered all the personal questions before then just present them with a summary to confirm or request to change
  if (!report && personalDetails.length>0) { 
    return (
     <>
     <Background/>
      <ConfirmPersonal 
        details={personalDetails}
        confirmDetails={()=>{ 
          setCounter({section: 0, question:4}); 
          setResponses(personalDetails); 
          setPersonalDetails([]) 
        } }
        requestChangeDetails={ () => setPersonalDetails([]) }
        />
     </>
    )
  }


  if (showThanksModal) return ( <Thanks okHandler={closeReport}/> )

  return (

      <>
      <View className='settingsReport'>

        <Background/>

        <TopMenu 
          page='report' 
          setReportID={setReportID} 
          setPersonalDetails={setPersonalDetails}
          />
        
        <Button onPress={()=>confirmExit()} clear  >
          <img 
          src={campfireImage} 
          style={{ 
            height : 60, 
            width : 'auto',
            position: 'absolute',
            top:10,
            left:10,
          }}  
          alt="Home"
          />
        </Button>
      
     {/*  <Button 
        onPress={() => navigation.openDrawer()}
        clear 
        >
        <Icon name="settings" size={40} color={Colours.white}  />
  </Button>*/}
      
      </View>

      <View className='reportContentContainer'>

        <div 
          ref={scrollRef}
          className='reportQuestionContainer'
          >

          <SectionTitle currentSection={currentSection} counter={counter}/>
          
          <QuestionTitle currentQuestion={currentQuestion} counter={counter}/>

          <View style={{display:'flex', flex:1}}>
            <QuestionForm 
              currentQuestion={currentQuestion} 
              handleResponse={handleResponse}
              handleMultipleResponses={handleMultipleResponses}
              responses={responses}
              handleUpload={handleUpload}
              scrollIntoView={scrollIntoView}
            />
          </View>
          
        </div>

        <Nav 
          sections={sections} 
          questions={questions}
          currentSection={currentSection} 
          setCurrentSection={setCurrentSection}
          sectionQuestions={sectionQuestions}
          counter={counter}
          setCounter={setCounter}
          currentQuestion={currentQuestion}
          saveHandler={saveHandler}
          responses={responses}
          complusoryQuestionsComplete={complusoryQuestionsComplete}
          confirmExit={confirmExit}
          />

        {alertMessage && renderAlert()}

      </View>

      </>
  );
};

   
    
export default ReportScreen;

