
import { auth} from '../../config';
import { getStorage, ref, uploadBytes, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage";

const storage = getStorage();
const authuser = auth.currentUser;

/**
 * upload audio function when submitting recorded audio to firebase
 *
 * @param uri - uri of audio to be uploaded
 * @param filepath - firestore filepath where audio will be uploaded to
 */
export const uploadAudioFile = async ( pickedFile, filepath ) => {
  const uri = pickedFile?.name;
  //add random audio name with appropriate file name extension
  const ext = uri.substring(uri.lastIndexOf('.'), uri.length);
  const audioName='audio' + Date.now() + ext;
  //create audio filepath where audio will be stored
  const audioPath = filepath + '/' + audioName;
  //get firebase storage
  const storageRef = ref(storage, audioPath);
  //convert audio to array of bytes
  //const aud = await fetch(uri);
  //const bytes = await aud.blob();
  //upload audio to firebase storage
  let result = null;
  let url = null;
  try {
    result = await uploadBytesResumable(storageRef, pickedFile);
  } catch (e) {
    alert('Upload error', e.message)
  }
  //return the URL where the audio has saved to
  try {
    url = await getDownloadURL(storageRef);
  } catch (e) {
    alert('Download URL error', e.message)
  }
  return (result && url) ? url : false;
}

/**
 * delete audio from firebase storage if it exists
 *
 * @param filepath - filepath to stored audio
 */
export const deleteAudioFile = async ( filepath ) => {
  if (filepath) {
    const deleteRef = ref(storage, filepath);
    try {
      await deleteObject(deleteRef);
    } catch (e) {
      alert('Delete error', e.message)
    }
  }
}
