import React, { useState, useEffect, memo } from 'react';

import { db, PageIDs, Colours } from '../../config';

import View from '../../components/UI/View';
import { getDoc, doc } from 'firebase/firestore';
import LoadingIndicator from '../../components/UI/LoadingIndicator';
import parse from 'html-react-parser';

const Introduction = ( ) => {

    const [pageContent, setPageContent] = useState();

    const getPageContent = async ( ) => {
        const page = PageIDs?.find(page => page.name === 'introduction');
        if (!page) return;
        const docRef = doc(db, "pagecontent", page.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
        setPageContent( { id:docSnap.data().id, html:docSnap.data()?.text});
        } else {
        console.log("Can't get page content");
        }
    }

    useEffect(() => {
        getPageContent();
        return () => { setPageContent(); };
    }, [])

    return (

        <View scroll={true} className='introductionContainer' >

            {!pageContent &&  <LoadingIndicator />}

            {pageContent?.html && parse(pageContent.html)} 
                
        </View>

  );
};

export default memo(Introduction);
