import React from 'react';

import View  from '../../components/UI/View';

const LoadingIndicator = ({message}) => {
  return (
    <View className='loadingContainer'>

      {message ? message : 'Loading'}

    </View>
  );
};

export default LoadingIndicator;
