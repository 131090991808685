import React, {createContext, useState} from 'react'

export const UserContext = createContext({
  currentUser: {},
  setCurrentUser: (user) => {},
  currentResponse: {},
  setCurrentResponse: () => {},
  responses: [],
  setResponses: () => {},
});

function UserContextProvider({children}) {
  const [currentUserObj, setCurrentUserObj] = useState();
  const [currentResponseObj, setCurrentResponseObj] = useState();
  const [responsesArray, setResponsesArray] = useState();

  function setCurrentUser(user) {
    setCurrentUserObj(user);
  }

  function setCurrentResponse(response) {
    setCurrentResponseObj(response);
  }

  function setResponses(resp) {
    setResponsesArray(resp);
  }


  const value = {
    user: currentUserObj,
    setCurrentUser: setCurrentUser,
    response: currentResponseObj,
    setCurrentResponse: setCurrentResponse,
    responses: responsesArray,
    setResponses: setResponses,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider;
