import React, { useState, useEffect, useContext, useRef } from 'react';

import { Formik } from 'formik';

import { Colours, db, auth, QuestionIDs} from '../../config';

import View from '../../components/UI/View';
import Alert from '../../components/Modals/Alert';
import TextInput from '../../components/UI/TextInput';
import Button from '../../components/UI/Button';
import ButtonRow from '../../components/UI/ButtonRow';
import LoadingIndicator  from '../../components/UI/LoadingIndicator';
import { UserContext } from '../../store/user-context';

import { doc, updateDoc, getDoc, setDoc, getDocs, collection, query, where,} from "firebase/firestore";
import { signOut, sendPasswordResetEmail } from 'firebase/auth';

import Modal from "./Modal";
const bgImage = require('../../assets/images/meetinggroups.png');

const MyProfile = ( { okHandler} ) => {

  const [working, setWorking] = useState(false);
  const nameQuestionID = QuestionIDs?.find(id => id.name === 'name')?.id;

  const authuser = auth.currentUser;

  const UserCtx = useContext(UserContext);
  const [user, setUser] = useState(UserCtx?.user);
  const [alertMessage, setAlertMessage] = useState();
  const [confirmMessage, setConfirmMessage] = useState(); 

  const formRef = useRef(null);

  //get user data from database 
  const getUserData = async () => {
    const docRef = doc(db, "users", authuser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //set user context
      const userData = docSnap.data();
      UserCtx.setCurrentUser(userData);
      setUser(userData)
    }
  }

  const cancelSubmit = () => {okHandler(); }

  const handleSubmit = async (data) => {

    setWorking(true);

    const updatedUser = {...user}

    if (data.name) {
      updatedUser[nameQuestionID] = data.name;
      setUser(updatedUser);
      UserCtx.setCurrentUser(updatedUser)
    }

    try {
      const userRef = doc(db, "users", authuser.uid);
      await updateDoc(userRef, updatedUser);
      setWorking(false);
      okHandler();
    } catch (err) {
      setWorking(false);
      console.log(err);
    }
  }

  const handleSignout = () => {
    signOut(auth).catch(error => console.log('Error logging out: ', error));
  };

  const handleSendPasswordResetEmail = () => {
    setWorking(true);
    sendPasswordResetEmail(auth, auth.currentUser.email)
      .then(() => {
        setWorking(false);
        setAlertMessage({ title:"Password reset", message: "Check your email for a password reset link"});
      })
      .catch(error => {
        setWorking(false);
        setAlertMessage({ title:"Password reset error", message: error.message});
      });
  };

  const handleDelete = () => {
    setConfirmMessage({ title:"Are you sure?", message: "This action will remove your saved details and finished reports from the app. To permanently delete your details and reports from our database, please contact us."});
  };
  const deleteProfile = async () => {
    setWorking(true);
    //deidentify user reports in database
    const reportsSnapshot = [];
    const q = query(collection(db, "reports"), where('User', '==', authuser.uid));
    const querySnapshot = await getDocs(q);
    let deleteError = false;
    querySnapshot.forEach(doc => { reportsSnapshot.push(doc.id); });
    reportsSnapshot?.map(async (id) => {
      const reportRef = doc(db, "reports", id);
      try {
        await updateDoc(reportRef, { User: ''});
      } catch (error){
        deleteError = error.message;
        setWorking(false);
      }
    });
    if (deleteError) {
      setAlertMessage({ title:"Error", message: 'There has been an error removing your reports. '+deleteError});
    }
    //deidentify user in collection
    const userRef = doc(db, 'users', authuser.uid);
    const docSnap = await getDoc(userRef);
    if ( docSnap.exists() && !deleteError){
      try {
        await setDoc( userRef, {
          email: '',
          RifMGPYPN3IcnRpczKR5:'', //name
          DgBiJbw6sYsFAmb50m2J:'', //email
          bepWgCABcn6bmlsYmQLp:'', //living
          gxalV5egR7C3ArF3zCmm:'', //identity
          [`bepWgCABcn6bmlsYmQLp postcode`]:'',
          [`bepWgCABcn6bmlsYmQLp suburb`]:'',
          [`bepWgCABcn6bmlsYmQLp state`]:'',
        }, { merge: true });
      } catch (error){
        setAlertMessage({ title:"Error", message: 'There has been an error removing your profile. '+error.message});
        setWorking(false);
      }
    }
    setWorking(false);
    if (!deleteError) handleSignout();
    };



    useEffect(() => {
      if (!authuser || !UserCtx) return;
      if (!UserCtx.user) getUserData();
    }, [authuser]);


  if (!user) {
    return(
      <View style={{
        display:'flex',
        flexDirection:"column",
        flex:1,
      }}>
        <LoadingIndicator message='Just a minute ...'/>
      </View>
    )
  }
    
    return (

        <Modal 
            align='top' 
            back 
            title='My Profile' 
            onCancel={okHandler}
            >

      <View style={{
        display:'flex',
        flexDirection:'column',
      }}>

<Formik
      innerRef={formRef}
      initialValues={{
        name: user[nameQuestionID]
      }}
      onSubmit={values => handleSubmit(values)}
    >
     {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
       <>

    <View scroll style={{display:'flex',
          flexDirection:"column",
          padding:20,
          overflow:'auto'}} >

        <TextInput
           name='name'
           leftIconName='person'
           placeholder='Your name'
           value={values.name}
           textContentType='name'
           onChangeText={handleChange('name')}
           onBlur={handleBlur('name')}
           style={{backgroundColor: Colours.white}}
         />

         <View style={{
            display:'flex',
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            paddingBottom:20,
          }}>
            
            {working  && 
            <LoadingIndicator message='Just a minute ...'/>
            }

            {!working  && <Button
             style={{
              color:Colours.colour1,
              marginTop: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
             borderless
             inverse
             title={'Sign out'}
             onPress={handleSignout}
           >
            Sign out
            </Button>}

            {!working  && <Button
             style={{
              color:Colours.colour1,
              marginTop: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
             borderless
             inverse
             title={'Sign out'}
             onPress={handleSendPasswordResetEmail}
           >
            Reset password
            </Button>}

            {!working  && <Button
             style={{
              color:Colours.colour1,
              marginTop: '16px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
             borderless
             inverse
             title={'Delete profile'}
             onPress={handleDelete}
           >
           Delete profile
            </Button>
            }
        

          </View>

          </View>

          <ButtonRow 
          cancelSubmit={cancelSubmit} 
          handleSubmit={handleSubmit}/>
       </>
     )}
   </Formik>

      </View>

      {confirmMessage && 
          <Alert 
          title={confirmMessage.title}
          message={confirmMessage.message}
          onConfirm={deleteProfile}
          onCancel={() => {setConfirmMessage();}}
          />
      }

      {alertMessage && 
          <Alert 
          title={alertMessage.title}
          message={alertMessage.message}
          onConfirm={() => setAlertMessage()}
          />
      }

      <img
        src={bgImage} 
        alt=""
        style={{
          position:'absolute',
          bottom:80,
          maxWidth:'20%',
          right:30,
          width:150, 
          height:'auto', 
          alignSelf:'center', 
          marginTop:20,
          opacity:.8
        }}
        />
        
        </Modal>

    );

}

export default MyProfile ;