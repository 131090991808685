import React, {useEffect, useContext, useState} from 'react';

import View  from '../../components/UI/View';
import Button from '../../components/UI/Button';
import Text from '../../components/UI/Text';
import { Colours } from '../../config';
import { UserContext } from '../../store/user-context'
//import AudioPlayer from '../../components/Media/AudioPlayer';

const RadioButton = ({ label, style, selectedOption, setSelectedOption, multiple, audios, disabled }) => {

    const UserCtx = useContext(UserContext);
 
    //console.log('selectedOption',selectedOption)
    //console.log(audios)
    //determine whether radio button should be selected depending on whether selectedOption is coming in as a string or an array
    const isSelected = Array.isArray(selectedOption) ? selectedOption.includes(label) : selectedOption === label;

    const [optionAudio, setOptionAudio] = useState();

    useEffect(() => {
        setOptionAudio();
        if (!audios || !UserCtx) return;
        //get the audio file we want to play for this question
        const audioLanguage = UserCtx.user?.language ?? "5ZtcuDbx0QaXjpggNCOR"; //default language is english
        const audio = audios?.find(audio => audio.language === audioLanguage);
        if (audio) setOptionAudio(audio.audio?.src);
      }, [audios, UserCtx]);

  return (
    <View style={{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'space-between',
        
    }}>

        <Button 
            clear 
            onPress={()=>{
                setSelectedOption(label)
            }} 
            style={{
                display:'flex',
                flexDirection: 'row',
                marginTop:15,
                marginBottom:15,
                alignItems: 'center',
            }}
        >

        <View style={{
            marginTop:4,
            height: 24,
            width: 24,
            minWidth:24,
            minHeight:24,
            border:'2px solid black',
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: disabled ? Colours.disabled : Colours.mediumGray,
            borderRadius: multiple ? 2 : '50%',
            marginRight:10
            }}>
        
            { isSelected ?

            <View style={{
                height: 12,
                width: 12,
                borderRadius: multiple ? 1 : '50%',
                backgroundColor: disabled ? Colours.colour1 : Colours.colour1
            }}/>
        
            : null
        
        }
        </View>

        <Text style={{
            marginHorizontal:20,
            fontSize:16,
            fontFamily:'roboto',
            textTransform:'none',
            color: Colours.black,
            textAlign:'left'
            }}>
            {label}
        </Text>
        
    </Button>

    {/* optionAudio && 

        <AudioPlayer 
        audio={optionAudio} 
        colour={Colours.colour1}
        showLabel={false}
        /> 

    */ }


    
  </View>


  );
};

export default RadioButton;

