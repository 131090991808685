import React from 'react';
import classes from './Button.module.css';

const Button = ({
  children,
  onPress,
  title,
  type,
  clear,
  flex,
  inverse,
  login,
  primary,
  grey,
  borderless,
  className,
  style
}) => {

  const _className = 
    flex ? classes.flex : 
    clear ? classes.clear : 
    inverse ? classes.inverse : 
    primary ? classes.primary :
    login ? classes.login :
    grey ? classes.grey :
    classes.default;

    const _classNameText = 
    inverse ? classes.inverseText : 
    primary ? classes.primaryText :
    borderless ? classes.defaultText:
    null;

  const _type = type ? type : 'button';

  if (borderless) {
    return (
      <button
        type={_type}
        onClick={onPress}
        className={`
        ${classes.clearButton} 
        ${classes.defaultText} 
        ${_classNameText}
        `}
        style={style}
        >
        {children}
      </button>
    );
  }

  return (
    <button 
      type={_type}
      onClick={onPress} 
      className={`
        ${classes.button} 
        ${_className}
        ${classes.defaultButtonText} 
        ${_classNameText}
        `}
      style={style}
      >
      {children}
    </button>
  );
};

export default Button;
