

import View from '../../components/UI/View';

import { Colours } from '../../config';

const ProgressBar = ({ progress }) => {
  
  return (
    <View style={{
      width:'100%',
      backgroundColor:Colours.lightBlue,
    }}>

      <View style={{
        'width': progress,
        'height': 5,
        'backgroundColor': Colours.green
        }}>
      </View>

    </View>
  )
};

export default ProgressBar;

