import React, {useEffect, useContext, useState} from 'react';
import Subheading from '../../components/UI/Subheading';
import View from '../../components/UI/View';
import Text from '../../components/UI/Text';
import { UserContext } from '../../store/user-context'
//import AudioPlayer from '../../components/Media/AudioPlayer';

const QuestionTitle = ({ currentQuestion, counter }) => {

  const [questionAudio, setQuestionAudio] = useState();
  const [questionTitle, setQuestionTitle] = useState();

    //console.log(currentQuestion)

    const UserCtx = useContext(UserContext);
    
    //console.log(questionAudio)

    useEffect(() => {
      setQuestionTitle(); setQuestionAudio();
      if (!counter || !UserCtx || !currentQuestion ) return;
      //get the audio file we want to play for this question
      const audioLanguage = UserCtx.user?.language ?? "5ZtcuDbx0QaXjpggNCOR"; //default language is english
      const audio = currentQuestion?.audios?.find(audio => audio.language === audioLanguage);
      if (audio) setQuestionAudio(audio.audio?.src);
      //get question title
      const title = `${counter.section+1}.${counter.question+1} ${currentQuestion.text}${currentQuestion.mandatory?'*':''}`;
      setQuestionTitle(title);
    }, [counter, currentQuestion, UserCtx]);

    if (!counter || !currentQuestion) return;
  
    return (

      <View className='titleContainer'>

      <View className='rowContainer'>

        <Subheading >{questionTitle}</Subheading>

        { /* questionAudio && 
        
         <AudioPlayer 
            audio={questionAudio} 
            colour={Colours.colour2}
            showLabel={false}
            /> 
        */
        }
      </View>

      {currentQuestion.info && 

        <Text style={{marginBottom:10}}>

          {currentQuestion.info}
          
        </Text>

      }

    </View>

  );
};

export default QuestionTitle;

