import React, { useState, useContext, useEffect } from 'react';

import { onAuthStateChanged } from 'firebase/auth';

import { AuthenticatedUserContext } from '../providers';

import LoadingIndicator from '../components/UI/LoadingIndicator';

import { auth, db} from '../config';

import Login from '../screens/Login';
import Error from '../screens/Error';
import Launch from '../screens/Launch';
import Share from '../components/App/PublicShare';

import { collection, addDoc, Timestamp } from "firebase/firestore";

import UserContextProvider from '../store/user-context'

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  { path: '/', element: <Login/>, errorElement: <Error />},
  { path: '/share/:reportId', title: 'Call It Out Report', element: <Share/> , errorElement: <Error />},
])

export const RootNavigator = () => {
  
  const { user, setUser } = useContext(AuthenticatedUserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // onAuthStateChanged returns an unsubscriber
    const unsubscribeAuthStateChanged = onAuthStateChanged(
      auth,
      authenticatedUser => {
        authenticatedUser ? setUser(authenticatedUser) : setUser(null);
        setIsLoading(false);
      }
    );
    if (user && user.email!=="mail.sarahbock@gmail.com") {recordUserSession();}
    // unsubscribe auth listener on unmount
    return unsubscribeAuthStateChanged;
  }, [user]);

  const recordUserSession = async () =>{
    try {
      await addDoc(collection(db, "sessions"), {
        id: user.uid,
        createdAt: Timestamp.fromDate(new Date())
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }


  return (
    
    <>

    {user ?

    <UserContextProvider>
      <Launch />
    </UserContextProvider>

    : <RouterProvider router={router }/>

    }

    </>
  );
};
