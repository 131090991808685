import React, { useState, useEffect } from 'react';


import View from '../../components/UI/View';
import Button from '../../components/UI/Button';
import Text from '../../components/UI/Text';
import Heading from '../../components/UI/Heading';
import Subheading from '../../components/UI/Subheading';

import {useLocation} from "react-router-dom";

import LoadingIndicator from '../UI/LoadingIndicator'; 

import { db, Colours, QuestionIDs } from '../../config';
import {  doc, onSnapshot, getDocs, collection, orderBy, query} from 'firebase/firestore';

const useQuery = () => { return new URLSearchParams(useLocation().search);  }

const Share = () => {

    const [report, setReport] = useState();
    const [questions, setQuestions] = useState();
    const [reportDate, setReportDate] = useState();
    let URLquery = useQuery();
   

    const openLink = async (url) => {
        window.open(url, 'callitout');
        };

    const renderImage = ({item, index}) => {
        if (!item) return;
        return (
          <View 
            style={{}} key={index}>
            { (item.indexOf('.jpg')!==-1 || item.indexOf('.jpeg')!==-1 || item.indexOf('.jpg')!==-1 || item.indexOf('.png')!==-1 || item.indexOf('.gif')!==-1) ? 
             <img src={item} style={{ width: 200, height: 200 }} /> :
             <Button 
                borderless
                style={{width: 'auto'}}
                onPress={()=>openLink(item)}>
                <span style={{
                    color:Colours.colour1, 
                    textDecoration:'underline'
                    }}>
                    Uploaded file
                </span>
            </Button>
          }
        </View>
        )
      }

    const getFormData = async (  ) => {
        //console.log('getting questions');
        const questionsArray = [];
        const sortedQuestionsArray = [];
        const sectionsArray = [];
        //get all questions from the database
        const querySnapshot1 = await getDocs(query(collection(db, "questions"), orderBy("order")));
        querySnapshot1.forEach((doc) => {
          questionsArray.push(doc.data());
        });
        //get all sections from the database
        const querySnapshot2 = await getDocs(query(collection(db, "sections"), orderBy("order")));
        querySnapshot2.forEach((doc) => {
          sectionsArray.push(doc.data());
        });
        //order questions according to section
        sectionsArray.forEach ( section => {
          const secQuestions = questionsArray.filter( question => question.section === section.id);
          sortedQuestionsArray.push(...secQuestions);
        });
        setQuestions(sortedQuestionsArray);
      }
      

    const QuestionText = ( { question } ) => {
        return (
            <Subheading colour={Colours.colour2}>
               {question.text}
            </Subheading>
        )
    }

    const SingleChoice = ( { question } ) => {
        //just get the question text from the response array
        //console.log(report[question.text])
        let responseText = report[question.text];
        //for single choice use the other information added if they have added an other response
        if (report[question.text +' '+ question.otherlabel +' details']) {
            responseText = report[question.text +' '+ question.otherlabel +' details']
        }
        if (responseText) return (
            <Text key={question.id}>
                { responseText }
            </Text>
        )
    }
    const MultipleChoice = ( { question } ) => {
        //get the question answers
        const answered = question.answers.map( a => {
            //console.log('checking whether answer',a.text,'is in the report',report[a.text])
            //replace commas to find exact match in reponse
            const adjustedText = a.text.replaceAll(",", "%2C");
            return (
                {answer: a.text, response: report[adjustedText]}
            )
        });
        
        const questionResponses = answered.filter (a => (a.response && a.response !=='')).map(r=> r.answer);
        let responseText = questionResponses.join(', ');
        //if they've selected other, show that as well
        if (report[question.text +' '+ question.otherlabel] && report[question.text +' '+ question.otherlabel] !=='') {
            responseText += 
                (responseText === "" ? '' : ', ') 
                + 
                ((report[question.text +' '+ question.otherlabel +' details']) ? 
                report[question.text +' '+ question.otherlabel +' details'] : 
                    question.otherlabel);
        }
       if (responseText) return (
            <Text key={question.id}>
                { responseText }
            </Text>
        )
    }
    const FreeTextAnswer  = ( { question } ) => {
        //console.log(report[question.text])
        //just get the question text from the response array
        if (report[question.text]) return (
            <Text key={question.id}>
                { report[question.text] }
         </Text>
        )
    }

    const Location  = ( { question } ) => {
        const suburb = report[question.text+" suburb"];
        const state = report[question.text+" state"];
        const postcode = report[question.text+" postcode"];
        //console.log(report.id)
        //console.log(question.text)
        return (
            <Text key={question.id}>
                {`${suburb ? suburb+' ' : ''}${state ? state+' ' : ''}${postcode ? postcode :''}`}
            </Text>
        )
    }
    
    useEffect(() => {
        // Taking reference of body element 
        let bodyElement = document.getElementsByTagName('body')[0]; 
        // Changing the class of body Before mounting 
        bodyElement.className = "overflow"; 
        const reportId = URLquery.get("id");
        if (!reportId) return;
        setReport();
        const unsubscribe = onSnapshot(doc(db, 'reports', reportId), (doc) => {
        if (doc.data()) { setReport(doc.data()); }
        getFormData();
      });
      return () => {
        unsubscribe();
        setReport();
      };
      }, []);
      

      //format last updated date
        useEffect(() => {
            setReportDate()
            if (report) {
                console.log(report)
            if (report.LastUpdateString) {
                const dateElements = report.LastUpdateString?.split('/');
                if (dateElements) {
                    const day = dateElements[0];
                    const month = dateElements[1]
                    const year = dateElements[2].substr(0,4);
                    const d = new Date(year, month, day);
                    const monthStr = d.toLocaleString('en-GB', { month: 'long' });
                    setReportDate(day+" "+monthStr+" "+year);
                    document.title = `Call It Out Report from ${day} ${monthStr} ${year}`
                }
                
            }
            }
        }, [report]);

    if (!report) return (
        <LoadingIndicator />
    )
    
    return (
      
    <>

            <View style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between',
                alignItems:'center',
                padding:20,
                paddingBottom:0,
            }}>
            <Heading centred>{`Call It Out Report from ${reportDate}`}</Heading>
            </View>


        <View style={{padding:20,}}>

            { questions?.map ( question => { 
                //ignore consent question
                const consentQuestionID = QuestionIDs?.find(id => id.name === 'consent')?.id;
                if (question.id===consentQuestionID) return;
               //get audio for the question - either audio answer or audio other answer
               const audioArray = question.other ? report[question.text +' '+question.otherlabel +' audio']?.split('%2C') : report[question.text +' audio']?.split('%2C');
               const imageArray = report[question.text +' image']?.split('%2C');
                
                return (
                    <View key={question.id}>
                        <QuestionText question={question}/>

                        { (question.type === "Short response" || question.type === "Long response") && <FreeTextAnswer question={question}/> }

                        { (question.type === "Location") && <Location question={question}/> }

                        { question.type === "Single choice" && <SingleChoice question={question}/> }
                        
                        { question.type === "Multiple choice" && <MultipleChoice question={question}/> }

                        {audioArray?.map((item,index) => renderImage ({item, index}) )}
            
                        {imageArray?.map((item,index) => renderImage ({item, index}) )}

                    </View>     
                )
            })}

            
        </View>

        {/*<View scroll style={{padding:20,}}>

            <ButtonRow 
            submitLabel="Close" 
            handleSubmit={()=> setModalVisible(!modalVisible)} 
            />

        </View>*/}

</>

    );

}

export default Share;