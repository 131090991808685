import React from 'react';

import View from '../../components/UI/View';
import Button from '../../components/UI/Button';

const ButtonRow = ({ cancelSubmit, handleSubmit, submitLabel, cancelLabel, style}) => {

  return (
    <View className='rowContainer' style={{columnGap:10}}>

        {cancelSubmit && 
        <Button 
        grey 
        onPress={cancelSubmit} 
        style={{flex:1, borderRadius:0}}
        >
              {cancelLabel ? cancelLabel : 'Cancel'}
        </Button>}

        {handleSubmit && 
        <Button 
        primary 
        onPress={handleSubmit} 
        style={{flex:1, borderRadius:0}}
        >
              {submitLabel ? submitLabel : 'Save'}
        </Button>}

        </View>
  );
};

export default ButtonRow;

