
import View from '../../components/UI/View';
import ButtonRow from '../../components/UI/ButtonRow';
import Text from '../../components/UI/Text';
import Heading from '../../components/UI/Heading';
import Subheading from '../../components/UI/Subheading';
import { QuestionIDs } from '../../config';
const locationQuestionID = QuestionIDs?.find(id => id.name === 'location')?.id;

const ConfirmPersonal = ({ details, requestChangeDetails, confirmDetails}) => {

    return (
   
        <View className='confirmPersonalContainer'>

        <Heading centred>Confirm your details</Heading>

        <View 
        scroll={true}
        style={{
          paddingTop:10,
          width:'100%',
          overflowY:'auto',
          overflowX:'hidden',
          display:'flex',
          flexDirection:'column',
          }}>

          { details?.map ( question => { 

             //ignore individual state, suburb, postcode entries
            if (question.id !== locationQuestionID+" suburb" && question.id !== locationQuestionID+" state" && question.id !== locationQuestionID+" postcode") {
                return (
                    <View key={question.id}>
                        <Subheading centred>
                          {question.column}
                        </Subheading>
  
                        <Text>
                            { question.response }
                        </Text>
  
                    </View>     
                )
            } else {
                return false;
            }
                
          })}

      <View style={{height:100}}></View>

</View>

<ButtonRow 
submitLabel="Confirm" 
cancelLabel="Change"
handleSubmit={confirmDetails}
cancelSubmit={requestChangeDetails}
/>

</View>

  );
};

export default ConfirmPersonal;

