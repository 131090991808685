import React from 'react';
import classes from './Heading.module.css';

const Heading = ({ children, inverse, centred }) => {

  return (
    <h1 className={` 
      ${classes.heading1} 
      ${inverse && classes.inverse} 
      ${centred && classes.centred}
      `}>
      {children}
    </h1>
    );
};

export default Heading;
