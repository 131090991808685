import React, { useState, useEffect } from 'react';

import { collection, query, getDocs, where, onSnapshot, orderBy } from 'firebase/firestore';

import { db, auth, Colours} from '../../config';

import Heading from '../../components/UI/Heading';
import View from '../../components/UI/View';
import Text from '../../components/UI/Text';
import ReportListItem from '../../components/ReportNav/ReportListItem';

import Modal from "./Modal";
const bgImage = require('../../assets/images/meetinggroups.png');

const MyReports = ( { okHandler} ) => {

    const authuser = auth.currentUser;
    const [userReports, setUserReports] = useState([]);
    const [questions, setQuestions] = useState();

    useEffect(() => {
        setUserReports([]);
        //query firestore to get any reports for that user
        const q = query( 
          collection(db, "reports"), 
          where('User', '==', authuser.uid),
          where('Finished', '==', true),
          orderBy('LastUpdate', 'desc'));
        const unsubscribe = onSnapshot(
          q,
          querySnapshot => {
            const reportsSnapshot = [];
            querySnapshot.forEach(doc => {
              //convert firebase time stamp to date
              const reportData ={...doc.data()}; 
              reportData.LastUpdate = doc.data().LastUpdate.toDate();
              //add doc id to data object
              reportData.id = doc.id;
              reportsSnapshot.push(reportData);
            });
            setUserReports(reportsSnapshot);
          },
          error => {
            console.log(error.message);
          }
        );
        return () => {
          //console.log('Detaching the database listener');
          unsubscribe();
          setUserReports([]);
        };
      }, [authuser]);
    
      const getQuestions = async (  ) => {
        //console.log('getting questions');
        const questionsArray = [];
        const sortedQuestionsArray = [];
        const sectionsArray = [];
        //get all questions from the database
        const querySnapshot1 = await getDocs(query(collection(db, "questions"), orderBy("order")));
        querySnapshot1.forEach((doc) => {
          questionsArray.push(doc.data());
        });
        //get all sections from the database
        const querySnapshot2 = await getDocs(query(collection(db, "sections"), orderBy("order")));
        querySnapshot2.forEach((doc) => {
          sectionsArray.push(doc.data());
        });
        //order questions according to section
          sectionsArray.map ( section => {
          const secQuestions = questionsArray.filter( question => question.section === section.id);
          sortedQuestionsArray.push(...secQuestions);
        });
        setQuestions(sortedQuestionsArray);
      }
    
        //get form data from firebase after component has initially loaded
        useEffect(() => {
          getQuestions();
        }, []);
    
    return (

        <Modal 
            align='top' 
            back 
            title='My Reports' 
            onConfirm={okHandler}
            onCancel={okHandler}
            >

      <View style={{
        display:'flex',
        flexDirection:'column',
      }}>

      <Heading centred>Finished reports</Heading>

      {userReports?.map((report) => (
          <ReportListItem 
            key={report.id}
            report={report}
            questions={questions}
            />
        ))}

      {userReports?.length === 0 && 
      <Text style={{
        color:Colours.black,
        fontFamily:'roboto',
        textTransform:'none',
        fontSize:'18px',
      }}> 
      Your finished reports will be listed here
      </Text>
      }

      </View>

      <img
        src={bgImage} 
        alt=""
        style={{
          position:'absolute',
          bottom:80,
          right:30,
          width:150, 
          height:'auto', 
          maxWidth:'20%',
          alignSelf:'center', 
          marginTop:20,
          opacity:.6,
          zIndex:-100
        }}
        />
        
        </Modal>

    );

}

export default MyReports ;