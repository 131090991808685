import React, { useState} from 'react';
import classes from './TopMenu.module.css';
import HamburgerMenu from './HamburgerMenu';
import SettingsIcon from '@mui/icons-material/Settings'; //https://mui.com/material-ui/material-icons/
import Button from '../UI/Button';

const TopMenu = ({  page, setReportID, setPersonalDetails }) => {

    const [menuVisible, setMenuVisible] = useState(false);

    return (

        <>

            <div className={classes.topMenu}>

                <Button clear onPress={() => setMenuVisible(!menuVisible)} >

                    {page === 'dashboard' ? 
                        <SettingsIcon sx={{color:'#D25B13', fontSize:43}}  /> :
                        <SettingsIcon sx={{color:'#FFFFFF', fontSize:43}}  /> 
                    }

                </Button>

            </div>

            <HamburgerMenu
                page = {page}
                visible={menuVisible}
                setMenuVisible={setMenuVisible}
                setReportID={setReportID}
                setPersonalDetails={setPersonalDetails}
            />

        </>

    );
}

export default TopMenu;