
import { auth} from '../../config';
import { getStorage, ref, uploadBytes, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage";
const storage = getStorage();
const authuser = auth.currentUser;

/**
 * upload image function when submitting picked image to firebase
 *
 * @param uri - uri of image to be uploaded
 * @param filepath - firestore filepath where image will be uploaded to
 */
export const uploadImageFile = async ( pickedFile, filepath ) => {
  //add random image name with appropriate file name extension
  const uri = pickedFile?.name;
  const ext = uri.substring(uri.lastIndexOf('.'), uri.length);
  const imageName='image' + Date.now() + ext;
  //create image filepath where image will be stored
  const imagePath = filepath + '/' + imageName;
  //get firebase storage
  const storageRef = ref(storage, imagePath);
  //convert image to array of bytes
  //const img = await fetch(pickedFile);
  //const bytes = await img.blob();
  //upload image to firebase storage
  let result = null;
  let url = null;
  try {
    result = await uploadBytesResumable(storageRef, pickedFile);
  } catch (e) {
    alert('Upload error', e.message)
  }
  //return the URL where the image has saved to
  try {
    url = await getDownloadURL(storageRef);
  } catch (e) {
    alert('Download URL error', e.message)
  }
  return (result && url) ? url : false;
}



/**
 * delete image from firebase storage if it exists
 *
 * @param filepath - filepath to stored image
 */
export const deleteImageFile = async ( filepath ) => {
  if (filepath) {
    const deleteRef = ref(storage, filepath);
    try {
      await deleteObject(deleteRef);
    } catch (e) {
      alert('Delete error', e.message)
    }
  }
}
