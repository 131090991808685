import TextInput from '../../components/UI/TextInput';

const ShortAnswer = ({ currentQuestion, handleResponse, defaultResponse }) => {


  const changeText = (e) => {
    handleResponse( 
      currentQuestion?.id, 
      currentQuestion?.text, 
      e);
  }

  return (

        <TextInput
            name={currentQuestion.id}
            placeholder='Type answer'
            autoFocus={false}
            onChangeText={changeText}
            defaultResponse={defaultResponse?.response ? defaultResponse?.response : ''}
        />

  );
};

export default ShortAnswer;

