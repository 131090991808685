import React from 'react';

const Text = ({style, children, selectable}) => {


  return (
    <div
      style={style}
      selectable={selectable}
      >
      {children}
    </div>
    );
};

export default Text;
