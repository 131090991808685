
export const aboutURL = 'https://callitout.com.au/about-us/';
export const privacyURL = 'https://callitout.com.au/privacy-policy/';
export const supportURL = 'https://callitout.com.au/support/';
export const resourcesURL = 'https://callitout.com.au/resources/';
export const contactURL = 'https://callitout.com.au/contact-us/';

  export const PageIDs = [
    {name:"about", id:'B76YtCdGlotFFnGV5sp5'},
    {name:"introduction", id:'TkbPcVMJuVLMBOAsAuhb'},
    {name:"thanks", id:'qpdWjuWsEGh0qUHd56Gs'},
    {name:"support", id:'h2K3YfUsa7jOgAwcp0J6'},
    {name:"privacy", id:'mRwG6jZ3zmjb0Th1J0yn'},
    {name:"contact", id:'YO5MU0s5aFjX7k9mMPGP'},
    {name:"using", id:'M4Bywr7VlDJcqsONCGdB'},
  ]

  export const QuestionIDs = [
    {name:"consent", id:'bxOQBJszU6vfyEXRA1Ga'},
    {name:"name", id:'RifMGPYPN3IcnRpczKR5'},
    {name:"location", id:'bepWgCABcn6bmlsYmQLp'},
  ]

  export const SectionIDs = [
    {name:"about", id:'VfmWPUpUHVA0ENy4XpME'},
  ]

