import React, { useState, useEffect } from 'react';

import View  from './View';
import { Colours } from '../../config';

const TextInput = ({
  name,
  placeholder,
  autoFocus,
  onChangeText,
  defaultResponse,
  style,
  type,
  multiline,
  ...otherProps
}) => {

  const [inputText, setInputText] = useState('');

  const onChangeInput = (e) => {
    const val = e.target.value ? e.target.value : '';
    onChangeText(val)
    setInputText(val)
  }

  useEffect(() => {
    setInputText('');
    if (defaultResponse)
    setInputText(defaultResponse)
  }, [defaultResponse]);

  return (
    <View
      style={{
        display:'flex',
        marginTop:10,
        marginBottom:10,
        flexDirection: 'row',
        padding: 0,
        width: '100%',
        ...style
      }}
    >
      
      {!multiline && <input 
        id={name}
        name={name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        value={inputText}
        onChange={onChangeInput}
        type= {type ? type : 'text'}
        spellCheck='true'
        style={{
          borderColor:Colours.colour2,
          width: '100%',
          fontSize: 14,
          height: 50,
          color: Colours.black,
          fontFamily: 'roboto'
        }}
        {...otherProps} 
        />}
      
      {multiline && 
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        value={inputText}
        onChange={onChangeInput}
        spellCheck='true'
        style={{
          borderColor:Colours.colour2,
          flex: 1,
          width: '100%',
          fontSize: 14,
          color: Colours.black,
          fontFamily: 'roboto'
        }}
        {...otherProps} 
        ></textarea>}

    </View>
  );
};

export default TextInput;
