import React, { useState } from 'react';
import { Colours, QuestionIDs} from '../../config';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SendIcon from '@mui/icons-material/Send';
import View from '../../components/UI/View';
import Button from '../../components/UI/Button';
import ButtonRow from '../../components/UI/ButtonRow';
import Text from '../../components/UI/Text';
import Heading from '../../components/UI/Heading';
import Subheading from '../../components/UI/Subheading';
//import AudioPlayer from '../../components/Media/AudioPlayer';

const ReportView = ({ questions, report, reportDate, modalVisible, setModalVisible, shareReport}) => {

    const QuestionText = ( { question } ) => {
        return (
            <Subheading colour={Colours.colour2}>
               {question.text}
            </Subheading>
        )
    }

    const openLink = async (url) => {
        window.open(url, 'callitout');
        };

    const renderImage = ({item, index}) => {
        if (!item) return;
        return (
          <View 
            style={{}} key={index}>
            { (item.indexOf('.jpg')!==-1 || item.indexOf('.jpeg')!==-1 || item.indexOf('.jpg')!==-1 || item.indexOf('.png')!==-1 || item.indexOf('.gif')!==-1) ? 
             <img src={item} style={{ width: 200, height: 200 }} /> :
             <Button 
                borderless
                style={{width: 'auto'}}
                onPress={()=>openLink(item)}>
                <span style={{
                    color:Colours.colour1, 
                    textDecoration:'underline'
                    }}>
                    Uploaded file
                </span>
            </Button>
          }
        </View>
        )
      }

    const SingleChoice = ( { question } ) => {
        //just get the question text from the response array
        //console.log(report[question.text])
        let responseText = report[question.text];
        //for single choice use the other information added if they have added an other response
        if (report[question.text +' '+ question.otherlabel +' details']) {
            responseText = report[question.text +' '+ question.otherlabel +' details']
        }
        if (responseText) return (
            <Text key={question.id}>
                { responseText }
            </Text>
        )
    }
    const MultipleChoice = ( { question } ) => {
        //get the question answers
        const answered = question.answers.map( a => {
            //console.log('checking whether answer',a.text,'is in the report',report[a.text])
            //replace commas to find exact match in reponse
            const adjustedText = a.text.replaceAll(",", "%2C");
            return (
                {answer: a.text, response: report[adjustedText]}
            )
        });
        
        const questionResponses = answered.filter (a => (a.response && a.response !=='')).map(r=> r.answer);
        let responseText = questionResponses.join(', ');
        //if they've selected other, show that as well
        if (report[question.text +' '+ question.otherlabel] && report[question.text +' '+ question.otherlabel] !=='') {
            responseText += 
                (responseText === "" ? '' : ', ') 
                + 
                ((report[question.text +' '+ question.otherlabel +' details']) ? 
                report[question.text +' '+ question.otherlabel +' details'] : 
                    question.otherlabel);
        }
       if (responseText) return (
            <Text key={question.id}>
                { responseText }
            </Text>
        )
    }
    const FreeTextAnswer  = ( { question } ) => {
        //console.log(report[question.text])
        //just get the question text from the response array
        if (report[question.text]) return (
            <Text key={question.id}>
                { report[question.text] }
         </Text>
        )
    }

    const Location  = ( { question } ) => {
        const suburb = report[question.text+" suburb"];
        const state = report[question.text+" state"];
        const postcode = report[question.text+" postcode"];
        //console.log(report.id)
        //console.log(question.text)
        return (
            <Text key={question.id}>
                {`${suburb ? suburb+' ' : ''}${state ? state+' ' : ''}${postcode ? postcode :''}`}
            </Text>
        )
    }
  
  return (

        <View
        style={{
            position:'absolute',
            top:0,
            left:0,
            backgroundColor:Colours.white,
            width:'100vw',
            minHeight:'100vh',
            maxHeight:'100vh',
            display:'flex',
            flexDirection:'column',
            zIndex:200
        }}>

            <View style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between',
                alignItems:'center',
                padding:20,
            }}>

                <Button 
                clear
                onPress={ ()=>setModalVisible(false) }
                style= {{}}>
                    <ArrowBackIosNewIcon sx={{fontSize:34, color:Colours.black, paddingHorizontal:10}}  />
                </Button>

                <Heading 
                style={{'paddingLeft':20, 'paddingRight':50}}
                centred>
                    {`Call It Out Report from ${reportDate}`}
                    </Heading>

                <Button 
                clear
                onPress={ ()=>{ setModalVisible(false); shareReport();} }
                style= {{}}>
                    <SendIcon sx={{fontSize:34, color:Colours.black, paddingHorizontal:10}}  />
                </Button>


            </View>


        <View scroll style={{
            overflow:'auto',
            flexGrow:1,
            padding:20,
            }}>

            { questions?.map ( question => { 
                //ignore consent question
                const consentQuestionID = QuestionIDs?.find(id => id.name === 'consent')?.id;
                if (question.id===consentQuestionID) return;
               //get audio for the question - either audio answer or audio other answer
               const audioArray = question.other ? report[question.text +' '+question.otherlabel +' audio']?.split('%2C') : report[question.text +' audio']?.split('%2C');
               const imageArray = report[question.text +' image']?.split('%2C');
                
                return (
                    <View key={question.id}>
                        <QuestionText question={question}/>

                        { (question.type === "Short response" || question.type === "Long response") && <FreeTextAnswer question={question}/> }

                        { (question.type === "Location") && <Location question={question}/> }

                        { question.type === "Single choice" && <SingleChoice question={question}/> }
                        
                        { question.type === "Multiple choice" && <MultipleChoice question={question}/> }

                        

                        {audioArray?.map((item,index) => renderImage ({item, index}) )}
                        {imageArray?.map((item,index) => renderImage ({item, index}) )}
            

                    </View>     
                )
            })}

            <View style={{ height:100 }}></View>
            
        </View>

        {/*<View scroll style={{padding:20,}}>

            <ButtonRow 
            submitLabel="Close" 
            handleSubmit={()=> setModalVisible(!modalVisible)} 
            />

        </View>*/}

        </View>

    );
};

export default ReportView;