import React, { useState, useEffect } from 'react';
import { Colours, db} from '../../config';
import Alert from '../../components/Modals/Alert';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SendIcon from '@mui/icons-material/Send';
import CircleIcon from '@mui/icons-material/Circle';
import View from '../UI/View';
import Button from '../UI/Button';
import Text from '../UI/Text';
//import Modal from "../Modals/Modal";
import ReportView from '../Report/ReportView';
import { doc, updateDoc } from "firebase/firestore";
import LoadingIndicator from '../UI/LoadingIndicator'; 

const ReportListItem = ({ report, questions }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [working, setWorking] = useState(false);
  const [reportDate, setReportDate] = useState();
  const [confirmMessage, setConfirmMessage] = useState();
  const [alertMessage, setAlertMessage] = useState();

  const messageShareSuccess = { title: "Share your report", msg: "A secure web link of your report has been created and copied to your clipboard. Use this link to share with others or print to PDF to save to your records."}
  const messageShareFailure = { title: "Error", msg: "We had some trouble creating a link. "}

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      if (successful) {
        setAlertMessage({ title:messageShareSuccess.title, message: messageShareSuccess.msg});
      } else {
        setAlertMessage({ title:messageShareFailure.title, message: messageShareFailure.msg});
      }
    } catch (err) {
      setAlertMessage({ title:messageShareFailure.title, message: messageShareFailure.msg+ " Error:" +err.message});
    }
    document.body.removeChild(textArea);
  }

  const shareReport = async () => {
     const shareLink = `https://register.callitout.com.au/share/report?id=${report.id}`
     if (!navigator.clipboard) {
         fallbackCopyTextToClipboard(shareLink);
         return;
       }
       navigator.clipboard.writeText(shareLink).then(function() {
        setAlertMessage({ title:messageShareSuccess.title, message: messageShareSuccess.msg});
       }, function(err) {
        setAlertMessage({ title:messageShareFailure.title, message: messageShareFailure.msg+ " Error:" +err.message});
       });
  };

  const confirmRemove = () => {
    setConfirmMessage({ title:"Are you sure?", message: "This action will remove the report from the app. To permanently delete your report from our database, please contact us."});
  }

  const removeHandler = async () => {
    //remove user id from report - keep report in database
    setConfirmMessage(); 
    setWorking(true);
    const reportRef = doc(db, "reports", report.id);
    await updateDoc(reportRef, { User: ''});
    setWorking(false);
  }

  //format last updated date
  useEffect(() => {
    setReportDate()
    if (report) {
      if (report.LastUpdate) {
        const d = new Date(report.LastUpdate);
        const day = d.getDate();
        const month = d.toLocaleString('en-GB', { month: 'long' });
        const year = d.getFullYear();
        setReportDate(day+" "+month+" "+year);
      }
    }
  }, [report]);

  if (working) return <LoadingIndicator />;

  if (modalVisible) return(
    <ReportView 
      questions={questions}
      report={report} 
      reportDate={reportDate}
      modalVisible={modalVisible} 
      setModalVisible={setModalVisible} 
      shareReport={shareReport}
    /> 
  )

  return (

    <View style={{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center',
      paddingTop:10,
      paddingBottom:5,
      minWidth:'30vw',
      backgroundColor:'#fdfbee8a',
    }}>

          <Button 
            clear 
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              marginVertical:5,
              alignItems:'center'
            }} 
            onPress={()=>setModalVisible(!modalVisible)}
            >
            <CircleIcon sx={{marginRight:5, fontSize:10, color:Colours.colour1}}  />
            <Text style={{
              color:Colours.black,
              fontFamily:'roboto',
              textTransform:'capitalize',
              fontSize:'18px',
            }}> 
              {reportDate}
            </Text>
          </Button>

        <View  style={{
          display:'flex',
          flexDirection:'row', 
          columnGap:10
          }}>
         <Button clear  onPress={shareReport}>
            <SendIcon sx={{fontSize:25, color:Colours.black}}  />
          </Button>

          <Button clear onPress={confirmRemove}>
            <RemoveCircleIcon sx={{color: Colours.red, fontSize:25 }}  />
          </Button>
        </View>

        {confirmMessage && 
            <Alert 
            title={confirmMessage.title}
            message={confirmMessage.message}
            onConfirm={removeHandler}
            onCancel={() => {setConfirmMessage();}}
            />
        }

        {alertMessage && 
            <Alert 
            title={alertMessage.title}
            message={alertMessage.message}
            onConfirm={()=>setAlertMessage()}
            />
        }
       

       
    </View>

    );
};

export default ReportListItem;
