import React from 'react';
import classes from './Heading.module.css';

const Subheading = ({ children, inverse, centred }) => {

  return (
    <h2 
      className={` 
      ${classes.heading2} 
      ${inverse && classes.inverse} 
      ${centred && classes.centred} 
      `}
      >
      {children}
      </h2>
    );
};

export default Subheading;

