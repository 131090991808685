import suncorner from '../../assets/images/suncorner.png';
import bluecampfire from '../../assets/images/bluecampfire2.png';
import meetinggroups from '../../assets/images/meetinggroups.png';
import stars from '../../assets/images/stars.png';
const Background = () => {
    
    return (
      <>

    <img src={suncorner} style={{
        position:'absolute', 
        width:300,
        maxWidth:'60%',
        height:'auto',
        top:-10, 
        left: -150,
        }} alt="" />

      <img src={bluecampfire} style={{
        position:'absolute', 
        width:200,
        maxWidth:'40%',
        height:'auto',
        top:-50, 
        right: -100,
        }} alt="" />

      <img src={meetinggroups} style={{
        position:'absolute', 
        width:150,
        maxWidth:'20%',
        height:'auto',
        bottom:60, 
        right: -50,
        }} alt="" />

      <img src={stars} style={{
        position:'absolute', 
        width:200,
        maxWidth:'20%',
        height:'auto',
        bottom:0, 
        left: 10,
        }} alt="" />
    

      
      </>
        
   
        )}

        export default Background;