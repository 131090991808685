import suncorner from '../../assets/images/suncorner.png';
import logo from '../../assets/images/logo.png';

const Background = () => {
    
    return (
      <>

    <img src={suncorner} style={{
      position:'absolute', 
      width:300,
      maxWidth:'60%',
      height:'auto',
      top:-10, 
      left: -150,
        }} alt="" />



      <img src={logo} style={{
        position:'absolute', 
        width:100,
        maxWidth:'10%',
        height:'auto',
        bottom:10, 
        left: 10,
        }} alt="Call It Out" />
    

      
      </>
        
   
        )}

        export default Background;