import React, { useState, useEffect } from 'react';

import { doc, getDoc } from 'firebase/firestore';
import { db, PageIDs } from '../../config';

import Modal from "./Modal";
import parse from 'html-react-parser';
const bgImage = require('../../assets/images/meetinggroups.png');

const Thanks = ( { okHandler} ) => {

    const [pageContent, setPageContent] = useState();
    const name = 'thanks';

    const getPage = async (name) => {
        const page = PageIDs?.find(page => page.name === name);
        if (!page) return;
        const docRef = doc(db, "pagecontent", page.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setPageContent(docSnap.data());
        } else {
            console.log("can't get page content", name);
        }
    }

    useEffect(() => {
        setPageContent();
        //get page info
        if (!name) return;
        getPage(name);
        return () => {
            setPageContent();
        };
      }, [])

      if (!pageContent) return (
        <></>
      )

    
    return (

        <Modal 
            align='top' 
            back 
            title={pageContent.title} 
            onConfirm={okHandler}
            onCancel={okHandler}
            >
        
            {pageContent.text && parse(pageContent.text)} 

            <img
                src={bgImage} 
                alt=""
                style={{
                position:'absolute',
                bottom:'10%',
                right:'10%',
                width:'20%', 
                height:'auto', 
                alignSelf:'center', 
                marginTop:20,
                opacity:.5
                }}
                />

        </Modal>

    );

}

export default Thanks ;