import React from 'react';
import Button from '../../components/UI/Button';
import { Colours } from '../../config';
import View from '../../components/UI/View';

import VfmWPUpUHVA0ENy4XpME from '../../assets/images/icon_VfmWPUpUHVA0ENy4XpME.png';
import qwqhwtMGRoabDcRHX4Bo from '../../assets/images/icon_qwqhwtMGRoabDcRHX4Bo.png';
import Gxep4XyRUNkXMvCdzroK from '../../assets/images/icon_Gxep4XyRUNkXMvCdzroK.png';
import g8YfJYVtKzNCy1KgXylP from '../../assets/images/icon_g8YfJYVtKzNCy1KgXylP.png';
import s5uIZT7NqkPAQ2NU3hbBw from '../../assets/images/icon_5uIZT7NqkPAQ2NU3hbBw.png';
import ZW1RuuvfcHcqwIPYbpSk from '../../assets/images/icon_ZW1RuuvfcHcqwIPYbpSk.png';

const SectionNavTile = ({ section, previewSection, setPreviewSection}) => {

  if (!section || !previewSection) return;

  let imageSource = null;

  switch (section.id) {
    case 'VfmWPUpUHVA0ENy4XpME': imageSource = VfmWPUpUHVA0ENy4XpME; break;
    case 'qwqhwtMGRoabDcRHX4Bo': imageSource = qwqhwtMGRoabDcRHX4Bo; break;
    case 'Gxep4XyRUNkXMvCdzroK': imageSource = Gxep4XyRUNkXMvCdzroK; break;
    case 'g8YfJYVtKzNCy1KgXylP': imageSource = g8YfJYVtKzNCy1KgXylP; break;
    case '5uIZT7NqkPAQ2NU3hbBw': imageSource = s5uIZT7NqkPAQ2NU3hbBw; break;
    case 'ZW1RuuvfcHcqwIPYbpSk': imageSource = ZW1RuuvfcHcqwIPYbpSk; break;
    default: imageSource = null;
}

  return (

    <Button 
      clear 
      onPress={()=>setPreviewSection(section)} 
      style={{
        display:'flex',
        justifyContent:'center',
        alignContent:'center',
        padding:0,
      }}
      >

      { section.icon && 
      
      <View 
      style={{
        backgroundColor: previewSection.id === section.id ? Colours.colour1 : 'transparent',
        opacity: previewSection.id === section.id ? 1 : .3,
      }}
      >
        <img
          style={{width:30,height: 30, margin:10, objectFit:'contain'}}
          src={imageSource}
          alt='icon'
        />
        </View>
      }

    </Button>

  );
};

export default SectionNavTile;

