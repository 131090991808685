import React from 'react';
import { Colours } from '../../config';
import Text  from '../../components/UI/Text';
import View  from '../../components/UI/View';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const MediaIcon = ({ icon, placeholder, size, colour }) => {

  const iconSize = size ? size : 52;

  const iconColour = colour ? colour : Colours.mediumGray;

  return (

    <View style={{alignItems: 'center'}}>

      { icon === 'delete' && <RemoveCircleIcon sx={{fontSize:iconSize, color: Colours.red, opacity: 0.8 }}  /> }

      { icon === 'upload' && <UploadFileIcon sx={{fontSize:iconSize, color: iconColour, opacity: 0.8 }}  /> }

      { icon === 'document' && <InsertDriveFileIcon sx={{fontSize:iconSize, color: iconColour, opacity: 0.8 }}  /> }

      { placeholder && <Text bold lines={1} small style={{color: iconColour}}> { placeholder } </Text>}
      
    </View>

  );
};

export default MediaIcon;


