export const Colours = {
  colour1: '#D25B13',
  colour2: '#1D4A83',
  colour3: '#85B3BE',
  lightBlue:'#E2EAEC',
  cream: '#FEE8AC',
  green: '#566842',
  lightGreen: '#82cebc',
  black: '#1e1f1e',
  offwhite: '#FDFBEE',
  white: '#FFFFFF',
  mediumGray: '#6e6869',
  lightGray: '#cccccc',
  red: '#BB2F28',
  disabled: '#c4c4c4'
};
