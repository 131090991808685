
import { Colours } from '../../config';
import QuestionMenu from '../../components/ReportNav/QuestionMenu';
import SectionMenu from '../../components/ReportNav/SectionMenu';
import View from '../../components/UI/View';

const FloatingMenu = ({ 
  sections, 
  setPreviewSection, 
  previewSection, 
  navOpen, 
  setNavOpen, 
  questions, 
  counter, 
  setCounter, 
  currentQuestion,
  responses,
  complusoryQuestionsComplete
 }) => {

  if (!navOpen) return;

  return (

    <View
    style={{
      display:'flex',
      flexDirection:'row',
      width:'100%',
    }}> 

    <View onclick={()=>setNavOpen(false)} style={{ width:'10%'}} />

    <View
    style={{
      display:'flex',
      flexDirection:'column',
      marginHorizontal:20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor:Colours.white,
      flex:1,
      paddingTop:10
    }}> 

 
    <QuestionMenu 
    navOpen={navOpen} 
    questions={questions}
    sections={sections}
    previewSection={previewSection} 
    setNavOpen={setNavOpen}
    counter={counter}
    setCounter={setCounter}
    currentQuestion={currentQuestion}
    responses={responses}
    complusoryQuestionsComplete={complusoryQuestionsComplete}
    />

    <SectionMenu 
    navOpen={navOpen} 
    sections={sections}
    previewSection={previewSection} 
    setPreviewSection={setPreviewSection}
    setNavOpen={setNavOpen}
    complusoryQuestionsComplete={complusoryQuestionsComplete}
    />


  </View>
  
  <View onclick={()=>setNavOpen(false)} style={{ width:'10%'}} />

    </View>
    

  
   
  );
};

export default FloatingMenu;

