import React, { useState } from 'react';

import classes from './HamburgerMenu.module.css';

import { signOut, sendPasswordResetEmail } from 'firebase/auth';
import { auth  } from '../../config';
import Button from '../UI/Button';
import { resourcesURL } from '../../config/constants';
import LoadingIndicator from '../UI/LoadingIndicator';
import Alert from '../Modals/Alert';
import Page from '../Modals/Page';
import MyReports from '../Modals/MyReports';
import MyProfile from '../Modals/MyProfile';

import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const HamburgerMenu = ({ page, visible, setMenuVisible, setReportID, setPersonalDetails }) => {

    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showReportsModal, setShowReportsModal] = useState(false);
    const [showModal, setShowModal] = useState({visible:false, name:''});

    const [working, setWorking] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const user = auth.currentUser;

    const renderButton = (title, onClick, external) => {
        if (working && title==='Change password') {
            return(
                <LoadingIndicator type='inverse' message='Sending password reset email ..'/>
            )
        }
        return (
            <Button flex onPress={onClick}>
                <div className={`${classes.HB_button} ${page === 'dashboard' ? classes.HB_bgColour1 : classes.HB_bgColour2}`}>
                    <div className={classes.HB_buttonText}> 
                    {title} 
                    { external && <OpenInNewIcon sx={{color:'#FFFFFF', fontSize:24}}  />}
                    </div>
                    
                </div>
            </Button>
        )
    }

    const handleChangePassword = () => {
         sendPasswordResetEmail(auth, user.email)
         .then(() => {
           setWorking(false);
           setAlertMessage({ title:"Password reset", message: "Check your email for a password reset link"});
         })
         .catch(error => 
           {
             setAlertMessage({ title:"Error", message: error.message});
             setWorking(false);
           });
    }


    const handleSignout = () => {
        signOut(auth).catch(error => console.log('Error logging out: ', error));
    };

    const handleSaveforlater = () => {
        setWorking(false);
        setPersonalDetails();
        setReportID('0');
        setMenuVisible(false);
    };

    const openLink = async (url) => {
        window.open(url, 'callitout');
        };


    if (!visible) return;

    if (showProfileModal === true) return (
        <MyProfile  okHandler={()=> { setShowProfileModal(false); }} />
    )

    if (showReportsModal === true) return (
        <MyReports  okHandler={()=> { setShowReportsModal(false); }} />
    )

    if (showModal.name !== '' ) return ( 
        <Page 
            setShowPage={()=>setShowModal({visible:false, name:''})} 
            name={showModal.name} 
        /> 
    )
    

    return (
        <div className={classes.HB_bg}>

            <div className={classes.HB_left} onClick={() => setMenuVisible(false)} >&nbsp;</div>

            <div className={`${classes.HB_container} ${page === 'dashboard' ? classes.HB_bg1 : classes.HB_bg2}`}>

                <div className={classes.HB_iconContainer}>

                    <Button clear onPress={() => setMenuVisible(false)} >

                        <CloseIcon sx={{color:'#FFFFFF', fontSize:32}}  />

                    </Button>
                   

                </div>

                <div className={classes.HB_scrollContainer}>

                    {!user.isAnonymous && page === 'dashboard' && renderButton('My profile', () => setShowProfileModal(true))}

                    {!user.isAnonymous && page === 'dashboard' && renderButton('My reports', () => setShowReportsModal(true))}

                    { page === 'dashboard' && renderButton('About', () => setShowModal({visible:true, name:'about'}))}

                    {renderButton('Using this app', () => setShowModal({visible:true, name:'using'}))}

                    {renderButton('Privacy policy', () => setShowModal({visible:true, name:'privacy'}))}

                    {renderButton('Support', () => setShowModal({visible:true, name:'support'}))}

                    {renderButton('Contact us', () => setShowModal({visible:true, name:'contact'}))}

                    {page === 'dashboard' && renderButton('Resources', () => openLink(resourcesURL), true)}

                    {!user.isAnonymous && page === 'dashboard' && renderButton(`Change password`, handleChangePassword)}

                    {page === 'dashboard' && renderButton(` ${user.isAnonymous ? 'Sign out (Guest)' : 'Sign out'}`, handleSignout)}

                    {/*!user.isAnonymous && page === 'report' && renderButton('Save for later', handleSaveforlater)*/}

                    {alertMessage && 
                        <Alert 
                        title={alertMessage.title}
                        message={alertMessage.message}
                        onConfirm={() => setAlertMessage()}
                        />
                    }

                </div>

            </div>
        </div>
    );

}

export default HamburgerMenu;