import React from 'react';
import Text from '../../components/UI/Text';
import Button from '../../components/UI/Button';
import { Colours } from '../../config';
//https://mui.com/material-ui/material-icons/
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import LoadingIndicator from '../../components/UI/LoadingIndicator';

const NavTile = ({ type, onPress, navOpen, working  }) => {

  if (type==='blank' || navOpen ) return (
    <Button 
    clear
    style={{
      display:'flex',
      flexDirection:'column',
      padding:0,
      alignItems:'center',
      
    }}></Button>
  )

  const renderIcon = (type) => {
    const size = 34;
    if (type === 'back') return (
      <ArrowBackIosNewIcon sx={{color: Colours.white, fontSize:size, }}  />
    )
    if (type === 'finish') return (
      <CheckCircleIcon sx={{color: Colours.colour3, fontSize:size, }}  />
    )
    if (type === 'disabled') return (
      <ArrowForwardIosIcon sx={{color: Colours.mediumGray, fontSize:size , }}  />
    )
    return (
      <ArrowForwardIosIcon sx={{color: Colours.colour1, fontSize:size , }}  />
    )
  }

  const getLabel = (type) => {
    switch (type) {
      case 'back':
        return 'Go back';
      case 'next':
        return 'Save and continue';
      case 'disabled':
        return 'Save and continue';
      case 'submit':
        return 'Finish';
      case 'finish':
        return 'Finish';
      default:
        return null;
    }
  }

  if (working) { 
    return <LoadingIndicator />;
  }

  return (
    
    <Button 
      clear 
      onPress={onPress} 
      style={{
        display:'flex',
        flexDirection:'column',
        padding:0,
        alignItems:'center',
      }}
      >

      { renderIcon(type) }

      <Text style={{
        fontSize:12,
        textAlign:'center',
        maxWidth:300,
        width:'100%',
        paddingTop:10,
        fontFamily:'roboto',
        textTransform:'none',
        color:Colours.white,
      }}>
        { getLabel(type) }
      </Text>
      
    </Button>

  );
};

export default NavTile;

