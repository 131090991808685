import React, {useState, useEffect} from 'react';

import View from '../../components/UI/View';
import RadioButton from '../../components/UI/RadioButton';
import OtherResponse from '../../components/Report/OtherResponse';
import Alert from '../../components/Modals/Alert';
import { Colours } from '../../config';

const MultipleChoice = ({ 
  currentQuestion, 
  handleMultipleResponses, 
  defaultResponses,
  defaultAudio,
  otherDetails,
  setOtherDetails,
  scrollIntoView,
  handleUpload, 
  responses 
}) => {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [limitReached, setLimitReached] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    
    //console.log('other details',otherDetails)
    //console.log('currentQuestion',currentQuestion);
    //console.log('selectedOptions',selectedOptions.length,currentQuestion.limitanswer);
    //console.log('defaultResponses',defaultResponses);
    //console.log(limitReached);
    

    useEffect(() => {
      if (!currentQuestion || !selectedOptions) return;
      const multipleResponses = [];
      currentQuestion.answers.map ( (answer, index) => {
        //Answers to multiple choice questions where more than one answer is allowed to be captured in multiple columns with each option having its own column (e.g. type of incident)
       const response = selectedOptions.includes( answer.text ) ? answer.text : '';
        //console.log(selectedOptions, answer.text, response);
        const responseObj = {
          id: currentQuestion.id+index,
          column: answer.text, 
          response: response
        };
        multipleResponses.push(responseObj);
      });
      //also add other response if this has been set up for the question
      if (currentQuestion.other) {
        const response = selectedOptions.includes( currentQuestion.otherlabel ) ? currentQuestion.otherlabel : '';
        const otherResponseObj = {
          id: currentQuestion.id+'other',
          column: currentQuestion.text+' '+currentQuestion.otherlabel, 
          response: response
        };
        multipleResponses.push(otherResponseObj);
        //also record anything they write in the other text input into the response
        const otherResponseDetailsObj = {
          id: currentQuestion.id+'otherdetails',
          column: currentQuestion.text+' '+currentQuestion.otherlabel+' details', 
          response: otherDetails
        };
        multipleResponses.push(otherResponseDetailsObj);
        //and record any audio they have put into the other section
        const otheraudioResponse = responses.find ( response => response.id === currentQuestion.id+' '+currentQuestion.otherlabel + " audio" );
        if (otheraudioResponse) {
          const otherResponseAudioObj = {
            id: currentQuestion.id+' '+currentQuestion.otherlabel+' audio',
            column: currentQuestion.text+' '+currentQuestion.otherlabel+' audio', 
            response: otheraudioResponse.response
          };
          multipleResponses.push(otherResponseAudioObj);
        }
      }
      handleMultipleResponses(multipleResponses);
      setLimitReached(currentQuestion.limitanswer > 0 && (selectedOptions.length >= currentQuestion.limitanswer));
    }, [selectedOptions, otherDetails]);

    useEffect(() => {
      setSelectedOptions(defaultResponses);
    }, [defaultResponses]);



    const setSelectedOption = ( val ) => {
      if (!selectedOptions) return;
        if (selectedOptions.includes(val)) {
            //remove from selection options array
            const newOptions = selectedOptions.filter((option) => option !== val);
            setSelectedOptions(newOptions);
        } else {
            //add to selected options array
            if (limitReached) {
              setAlertMessage({ title:"Response limit reached", message: 'You have selected the maximum number of responses.'});
            } else {
              setSelectedOptions([...selectedOptions, val]);
            }
        }
    }

  return (
    
    <View 
      style={{
        paddingHorizontal:20,
        borderColor:Colours.colour1,
        paddingRight:10,
        borderRightWidth: currentQuestion?.answers?.length>7 ? 3 : 0
      }}>

        {currentQuestion.answers?.map(answer => (

            <RadioButton 
            key={answer.text} 
            selected={false} 
            label={answer.text}
            multiple={true}
            selectedOption={selectedOptions}
            disabled={limitReached}
            setSelectedOption ={setSelectedOption}
            />

        ))}

        {currentQuestion.other &&
          <OtherResponse 
              currentQuestion={currentQuestion}
              selectedOption={selectedOptions}
              setSelectedOption ={setSelectedOption}
              multiple={true}
              otherDetails={otherDetails}
              setOtherDetails = {setOtherDetails}
              scrollIntoView={scrollIntoView}
              handleUpload={handleUpload}
              defaultResponse={defaultResponses}
              defaultAudio={defaultAudio}
            />
        }

      {alertMessage && 
        <Alert 
        title={alertMessage.title}
        message={alertMessage.message}
        onConfirm={() => setAlertMessage()}
        />
      } 

  </View>

  );
};

export default MultipleChoice;