import React, { useState, useEffect } from 'react'

import RadioButton from '../../components/UI/RadioButton';
import View from '../../components/UI/View';
import TextInput from '../../components/UI/TextInput';
import { STATES } from '../../config/locations';


const Location = ({ currentQuestion, handleResponse, defaultResponses }) => {

  //console.log('defaultResponses', defaultResponses)

  const [suburbText, setSuburbText] = useState();
  const [stateText, setStateText] = useState(defaultResponses?.find ( response => (response.column?.indexOf('state') !==-1))?.response);
  const [postcodeText, setPostcodeText] = useState();

  const setSelectedState = ( val ) => {
    if (val === stateText) {
      //clear radio button
      setStateText();
    } else {
      setStateText(val);
    }
    handleResponse( 
      currentQuestion?.id+" state", 
      currentQuestion?.text+" state", 
      val ? val : ''
      );
  }

  const changeSuburbText = (val) => {
    setSuburbText(val)
    handleResponse( 
      currentQuestion?.id+" suburb", 
      currentQuestion?.text+" suburb", 
      val);
  }

  const changePostcodeText = (val) => {
    console.log(val)
    setPostcodeText(val)
    handleResponse( 
      currentQuestion?.id+" postcode", 
      currentQuestion?.text+" postcode", 
      val);
    }

    useEffect(() => {
      if (!currentQuestion ) return;
      handleResponse( 
        currentQuestion?.id+" state", 
        currentQuestion?.text+" state", 
        stateText);
    }, [stateText]);

  useEffect(() => {
    setStateText()
    if (!defaultResponses) return;
    setStateText(defaultResponses?.find ( response => (response.column?.indexOf('state') !==-1))?.response);
  }, [defaultResponses]);
    
  return (
    
    <View
      style={{
        display:'flex',
        flexDirection:'column',
        flexGrow:1
      }}>

          <TextInput
            name='suburb'
            placeholder='Suburb or community'
            autoFocus={false}
            onChangeText={changeSuburbText}
            defaultResponse={defaultResponses?.find ( response => (response.column?.indexOf('suburb') !==-1))?.response}
          />

          <TextInput
            name='postcode'
            placeholder='Type postcode'
            autoFocus={false}
            onChangeText={changePostcodeText}
            type='number'
            defaultResponse={defaultResponses?.find ( response => (response.column?.indexOf('postcode') !==-1))?.response}
          />


          <View 
          style={{
            display:'flex', 
            flexDirection:'row', 
            width:'100%', 
            flexWrap:'wrap',
            paddingLeft:20
            }}>

          {STATES?.map(state => (
            <View 
              key={state.id} 
              style={{
                display:'flex',
                width:'33%', 
                }}>
                  <RadioButton 
                    key={state.id} 
                    selected={false} 
                    label={state.id}
                    selectedOption={stateText}
                    setSelectedOption ={setSelectedState}
                  />
            </View>
        ))}
        </View>
  </View>

  );
};

export default Location;

