import React, {useState, useEffect} from 'react';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Text from '../../components/UI/Text';
import Button from '../../components/UI/Button';
import RadioButton from '../../components/UI/RadioButton';
import Alert from '../Modals/Alert';
//import AudioPlayer from '../../components/Media/AudioPlayer';
//import AudioRecorder from '../../components/Media/AudioRecorder';
import LoadingIndicator from '../../components/UI/LoadingIndicator';
import View from '../../components/UI/View';
import TextInput from '../../components/UI/TextInput';
import { Colours } from '../../config';


const OtherResponse= ({ 
  currentQuestion, 
  selectedOption, 
  setSelectedOption, 
  multiple,
  otherDetails,
  setOtherDetails,
  scrollIntoView,
  handleUpload,
  defaultResponse,
  defaultAudio
}) => {

    const otherLabel = currentQuestion.otherlabel ? currentQuestion.otherlabel : 'Other';
    //determine whether radio button should be selected depending on whether selectedOption is coming in as a string or an array

    const isSelected = Array.isArray(selectedOption) ? selectedOption.includes(otherLabel) : selectedOption === otherLabel;
    //console.log(selectedOption)
    //console.log('other details',otherDetails)
    //console.log('defaultResponse',defaultResponse)

    const [alertMessage, setAlertMessage] = useState();
    

    const [limitMessage, setLimitMessage] = useState();
    const [inputText, setInputText] = useState();
    const characterLimit = 1000;
    const [audio, setAudio] = useState();
    const [working, setWorking] = useState(false);

    //console.log('defaultAudio',defaultAudio)
    //console.log('audio',audio)

    const confirmRemove = (item) => {
      setAlertMessage({ title:"Are you sure?", message: "This action will remove the audio from your report."});
     /* Alert.alert('Are you sure?', `This action will remove the audio from your report.`, [
        {text: 'Cancel', style: 'cancel'},
        {text: `I'm sure`, onPress: ()=>removeHandler(item)},
      ]);*/
    }
  
    const removeHandler = async (item) => {
      setWorking(true);
      const success = await handleUpload('audio', item, currentQuestion, 'delete');
      if (success) setAudio();
      setWorking(false);
    }
  
    //when audio is added via the recorder it updates the state here so they can play back the recorded files.
    const addAudio = ( uri ) => { 
      if ( !uri ) return;
      setAudio(uri);
    }


    const changeText = (val) => {
      const characterCount = inputText ? inputText.length+1 : '1';
      setLimitMessage(`${characterCount ? characterCount : 0}/${characterLimit}`);
      setOtherDetails(val);
      setInputText(val);
    }

    const renderAudio = (item) => {
      if (!item) return;
      if (working) {
        return (
            <LoadingIndicator size='small' message={'Working'} />
        );
      }
      return (
        <View style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'center',
          alignItems:'center'
        }}>
          {/*<AudioPlayer audio={item}  /> */}
        <Button
          clear
          onPress={() => confirmRemove(item)}
          >
            <RemoveCircleIcon sx={{color: Colours.red, fontSize:30, marginLeft:10 }}  />
        </Button>
      </View>
      )
    }

    useEffect(() => {
      setInputText()
    }, [currentQuestion]);


    useEffect(() => {
      if (isSelected) {
        scrollIntoView(); 
      }
    }, [isSelected]);

    //get audio string from default value
   useEffect(() => {
    setAudio();
    if ( defaultAudio ) {
      setAudio(defaultAudio);
    }
  }, [defaultAudio]);


  return (
    
    <View>

          <RadioButton 
            key={otherLabel} 
            selected={false} 
            label={otherLabel}
            multiple={multiple}
            selectedOption={selectedOption}
            setSelectedOption ={setSelectedOption}
            />

            { isSelected  &&
            <>

            {limitMessage && 
            <Text 
              style={{
                position:'relative',
                marginTop:-10,
                fontSize:12,
                textAlign:'right',
                paddingRight:5,
                color:Colours.red
              }}>
               {limitMessage} 
               </Text>}
             
              <TextInput
                  name='otherresponse'
                  placeholder='Please provide more details'
                  autoFocus={false}
                  multiline={true}
                  defaultResponse={otherDetails}
                  onChangeText={changeText}
                  maxLength={characterLimit}
                  style={{
                    marginTop:0,
                    height:100
                  }}
                />

                <View style={{
                  display:'flex',
                  flexDirection:"column",
                  alignItems:"center",
                  justifyContent:"center",
                  rowGap:20,
                  marginBottom:10
                }}>

                {/*!audio && 
                  <AudioRecorder
                  handleUpload={handleUpload}
                  currentQuestion={currentQuestion}
                  addAudio={addAudio}
                  />
                */} 
                {audio && 
                  <View style={{
                      display:'flex',
                      flexDirection:'row',
                      minWidth: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                  {/*renderAudio(audio)*/}
                  </View>
                  }

                </View>

                {alertMessage && 
                  <Alert 
                  title={alertMessage.title}
                  message={alertMessage.message}
                  onConfirm={() => setAlertMessage()}
                  />
                }
                
            </>

            }

  </View>

  );
};

export default OtherResponse;



