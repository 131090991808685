import React, { useState, useEffect} from 'react';
import Text from '../../components/UI/Text';
import View from '../../components/UI/View';
import TextInput from '../../components/UI/TextInput';
import Button from '../../components/UI/Button';
import Alert from '../Modals/Alert';
import LoadingIndicator from '../../components/UI/LoadingIndicator';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FilePicker from '../Media/FilePicker';

import { Colours } from '../../config';

const LongAnswer = ({ currentQuestion, handleResponse, defaultResponse, handleUpload }) => {

  const [audio, setAudio] = useState();
  const [audioLimitReached, setAudioLimitReached] = useState(false);
  const [limitMessage, setLimitMessage] = useState();
  const [inputText, setInputText] = useState();
  const [working, setWorking] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(); 
  const [itemToRemove,setItemToRemove] = useState();

  //console.log(currentQuestion.limitanswer)
  //console.log('audio',audio?.length);
  //console.log('defaultResponse',defaultResponse)

  
  const confirmRemove = (item) => {
    setItemToRemove(item);
    setConfirmMessage({ title:"Are you sure?", message: "This action will remove the file from your report."});
  }

  const removeHandler = async (item) => {
    if (!item) return;
    setWorking(true);
    const success = await handleUpload('audio', item, currentQuestion, 'delete');
    if (success) removeAudio (item); //add to state in question to enable playback of multiple files
    setItemToRemove();
    setWorking(false);
  }

  const openLink = async (url) => {window.open(url, 'callitout');};

  //when audio is added via the recorder it updates the state here so they can play back the recorded files.
  const addAudio = ( uri ) => { 
    if ( !uri ) return;
    const newAudios = audio ? [...audio] : [];
    newAudios.push(uri);
    setAudio(newAudios);
  }

  const removeAudio = ( uri ) => { 
    if ( !uri ) return;
    const newAudios = audio?.filter((aud) => aud !== uri);
    setAudio(newAudios);
  }

  const changeText = (val) => {
    if (currentQuestion.limitanswer) {
      const characterCount = inputText ? inputText.length+1 : '1';
      setLimitMessage(`${characterCount ? characterCount : 0}/${currentQuestion.limitanswer}`);
    } else {
      setLimitMessage()
    }
    setInputText(val)
    handleResponse( 
      currentQuestion?.id, 
      currentQuestion?.text, 
      val);
  }

  const renderAudio = (item, index) => {
    if (!item) return;
    if (working) {
      return (
        <View style={{
          display:'flex',
          flex: 1,
          flexDirection:'row',
          alignItems:'center',
          columnGap:15
        }}>
          <LoadingIndicator size='small' message={'Working'} />
        </View>
      );
    }
    return (
      <View 
        style={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          columnGap:15,
          marginBottom: 20,
        }} 
        key={index}>
          <Button 
          borderless
          onPress={()=>openLink(item)}>
          <span style={{
            color:Colours.colour1, 
            textDecoration:'underline'
            }}>
              Uploaded file
            </span>
          </Button>
          <Button
        clear
        onPress={() => confirmRemove(item)}
        >
          <RemoveCircleIcon sx={{fontSize:30, color: Colours.red, }}  />
      </Button>
    </View>
    )
  }

  useEffect(() => {
    setInputText()
  }, [currentQuestion]);

   //get audio string from default values and split into array ready to send to the playback list
   useEffect(() => {
    setAudio();
    if ( defaultResponse?.audio ) {
      const defaultAudios = defaultResponse?.audio.split(',');
      //const defaultAudioArray = defaultAudios.map ( (el, index) => { return ({id: index, src: el}) })
      setAudio(defaultAudios);
    }
  }, [defaultResponse]);

  //see if we have reached the limit of number of files allowed
  useEffect(() => {
    setAudioLimitReached(false);
    if ( !audio || !currentQuestion?.allowaudio ) return;
    const limitReached = audio?.length >= currentQuestion?.numberfiles;
    setAudioLimitReached(limitReached)
  }, [audio, currentQuestion]);

  return (
    
    <View 
      style={{
        display:'flex', 
        flexDirection:'column', 
        flexGrow:1
      }}>

        <TextInput
            name={currentQuestion.id}
            placeholder='Type answer'
            autoFocus={false}
            multiline={true}
            onChangeText={changeText}
            defaultResponse={defaultResponse?.response ? defaultResponse?.response : ''}
            maxLength={currentQuestion.limitanswer}
            style={{ flexGrow:1,}}
        />

        {limitMessage && 
          <Text style={{
            fontSize:12,
            textAlign:'right',
            color:Colours.red
            }}>
            {limitMessage}
          </Text>
        }

        {currentQuestion.allowaudio && 

        <View style={{
          display:'flex',
          flexDirection:"column",
          alignItems:"center",
          justifyContent:"center",
          paddingTop:20
        }}>

        {audio?.map( (item, index) => renderAudio(item, index))}

        {!audioLimitReached && 
          <FilePicker
          handleUpload={handleUpload}
          currentQuestion={currentQuestion}
          addFile={addAudio}
          type="audio"
          />
        } 

        {confirmMessage && 
          <Alert 
          title={confirmMessage.title}
          message={confirmMessage.message}
          onConfirm={()=> {removeHandler(itemToRemove); setConfirmMessage();}}
          onCancel={() => { setItemToRemove(); setConfirmMessage();}}
          />
        }
        </View>
        
        }

  </View>

  );
};

export default LongAnswer;

