import React, { useState, useRef} from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signInAnonymously} from 'firebase/auth';
import { auth, db } from '../config';
import { doc, setDoc, Timestamp} from 'firebase/firestore';

import Background from '../components/Login/Background';
import Button from '../components/UI/Button';
import Heading from '../components/UI/Heading';
import LoadingIndicator from '../components/UI/LoadingIndicator';
import { aboutURL, privacyURL } from '../config/constants';

import logoImage from '../assets/images/icon.png';


import Alert from '../components/Modals/Alert';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const isEmail = (email) => {
    var regex = /^[_a-zA-ZáéíñóúüÁÉÍÑÓÚÜ0-9-]+(\.[_a-zA-ZáéíñóúüÁÉÍÑÓÚÜ0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/; return regex.test(email);
  }

const Login = () => {
    
    const emailRef=useRef();
    const passwordRef=useRef();
    const confirmPasswordRef=useRef();
    const [working, setWorking] = useState(false);
    const [mode, setMode] = useState();


    const [alertMessage, setAlertMessage] = useState();

    const [passwordVisible, setPasswordVisible] = useState(false);

    const openLink = async (url) => { window.open(url, 'callitout'); };
   
    const launchGuestHandler = () => {
      setWorking(true);
      signInAnonymously(auth)
      .then(async (userCredential) => {
        setWorking(false);
        //created account, now write to DB
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          id: user.uid,
          email: user.email,
          createdAt: Timestamp.fromDate(new Date())
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const alertText = 'Sorry! Signing in as a guest did not work. '+errorCode+errorMessage;
        setAlertMessage({ title:"Error", message: alertText});
        setWorking(false);
      });
    }

    const submitHandler = async (event) =>{
        //submit form, prevent default form functionality
        event.preventDefault();

        //get email from form field
        const enteredEmail = emailRef.current.value ? emailRef.current.value.toLowerCase() : null;
        if (!enteredEmail){
          setAlertMessage({ title:"Error", message: "Please enter a valid email address"});
          return;
        }

        if (enteredEmail && isEmail(enteredEmail)===false){
          setAlertMessage({ title:"Error", message: "Please enter a valid email address"});
          return;
        }

        setWorking(true);

        if (mode ==='Reset password') {
          //forgot password
          sendPasswordResetEmail(auth, enteredEmail)
          .then(() => {
            setWorking(false);
            setAlertMessage({ title:"Password reset", message: "Check your email for a password reset link"});
            setMode('Log in');
          })
          .catch(error => 
            {
              setAlertMessage({ title:"Error", message: error.message});
              setWorking(false);
            });
        } else {

          //get password from form field
          const enteredPassword = passwordRef.current.value;
          if (enteredPassword===""){ 
            setAlertMessage({ title:"Hold on!", message: "Please enter a password"});
            setWorking(false); 
            return; 
          }

          if (enteredPassword.length < 6){ 
            setAlertMessage({ title:"Hold on!", message: "Password should be at least 6 characters."});
            setWorking(false); 
            return; 
          }

          if (mode ==='Log in') {

            //try signing in with email and password
            signInWithEmailAndPassword(auth, enteredEmail, enteredPassword)
            .then((userCredential) => {
              // Signed in
            })
            .catch((error) => {
              const errorCode = error.code;
              let errorMessage = error.message;
              switch (errorCode) {
                case 'auth/user-not-found' :
                  errorMessage = "We can't find a user with this email address"; break;
                case 'auth/wrong-password' :
                  errorMessage = "Password is incorrect"; break;
                case 'auth/too-many-requests' :
                  errorMessage = "Too many failed login attempts. Try again later or reset your password."; break;
                default: errorMessage = "General error with sign in. Error code: "+errorCode;
               }
              setAlertMessage({ title:"Error", message: errorMessage});
              setWorking(false);
            });

          } else if (mode ==='Sign up')  {

              //make sure passwords match
              const confirmPassword = confirmPasswordRef.current.value;
              if (confirmPassword===""){ 
                setAlertMessage({ title:"Hold on!", message: "Please confirm your password"});
                setWorking(false); 
                return; 
              }
              if (confirmPassword !== enteredPassword){
                setAlertMessage({ title:"Error", message: "Passwords don't match"});
                setWorking(false); 
                return;
              }

              //try creating an account with email and password
              createUserWithEmailAndPassword(auth, enteredEmail, enteredPassword)
              .then( async (userCredential) => {
                  setWorking(false);
                  //created account, now write to DB
                  const user = userCredential.user;
                  const newUserRef = await setDoc(doc(db, "users", user.uid), {
                    id: user.uid,
                    email: user.email,
                    createdAt: Timestamp.fromDate(new Date())
                  });
                }).catch((error) => {
                  const errorCode = error.code;
                  let errorMessage = error.message;
                  switch (errorCode) {
                    case 'auth/email-already-in-use' :
                      errorMessage = "This email is already in use."; break;
                    case 'auth/weak-password' :
                      errorMessage = "Password should be at least 6 characters."; break;
                    case 'auth/too-many-requests' :
                      errorMessage = "Too many failed login attempts. Try again later or reset your password."; break;
                    default: errorMessage = "General error with creating an account. Error code: "+errorCode;
                   }
                   setAlertMessage({ title:"Error", message: errorMessage});
                  setWorking(false);
                });
          }
        }
      };

      if (working) {
        return(
          <div className='container bg1 loginForm' >
            <LoadingIndicator type='inverse' message='Working ..'/>
          </div>
        )
      }

      if (!mode) return (
        <div className='container bg1 loginForm '>
          <Background/>
          <div className="launchLogoHolder">
            <img src={logoImage} className="launchLogo" alt="Call It Out logo"/>
          </div>
          <Heading inverse> Call It Out </Heading>
          <Button login onPress={() => setMode('Log in')}>Log in</Button>
          <Button login onPress={() => setMode('Sign up')}>Sign up</Button>
          <Button login onPress={launchGuestHandler}>Use as guest</Button>
          <Button  borderless inverse onPress={()=>openLink(aboutURL)} > About Call It Out </Button>
          <Button  borderless inverse onPress={()=>openLink(privacyURL)}  > Privacy </Button>
        </div>
      )

      return (

          <form onSubmit={submitHandler} className='container bg1 loginForm '>

          <Background/>

          <div className="launchLogoHolder">
            <img src={logoImage} className="launchLogo" alt="Call It Out logo"/>
          </div>

          <Heading centred inverse>
          {mode==='Sign up' && <>Create a new account</>}
          {mode==='Log in' && <>Welcome</>}
          {mode==='Reset password' && <>Reset your password</>}
          </Heading>
                
          <div className='loginButton inputDiv'>
            <input 
            type="email" 
            id="email" 
            required 
            placeholder='Email' 
            ref={emailRef} 
            spellCheck='false' 
            />
          </div>

          { /*Password required in sign up and login modes */
          (mode==='Sign up' || mode==='Log in')  && 
          <div className='loginButton inputDiv passwordInputDiv'>
            <input 
                type={passwordVisible ? 'text' : 'password'} 
                id="password" 
                required 
                spellCheck='false'
                placeholder='Password' 
                ref={passwordRef}  
                />
                {passwordVisible ? 
                  <VisibilityOutlinedIcon
                  className="passwordVisibilityIcon" 
                  style={{ color: "#999999", fontSize: 20}}
                  onClick={()=>setPasswordVisible(!passwordVisible)} 
                  /> : 
                  <VisibilityOffOutlinedIcon
                  className="passwordVisibilityIcon" 
                  style={{ color: "#999999", fontSize: 20}}
                  onClick={()=>setPasswordVisible(!passwordVisible)} 
                  />
                }  
          </div>
          }

        { /*Password repeat required in sign up mode */
          (mode==='Sign up' )  && 
          <div className='loginButton inputDiv passwordInputDiv'>
            <input 
                type={passwordVisible ? 'text' : 'password'} 
                id="confirmPassword" 
                required 
                spellCheck='false'
                placeholder='Repeat password' 
                ref={confirmPasswordRef}  
                />
                {passwordVisible ? 
                  <VisibilityOutlinedIcon
                  className="passwordVisibilityIcon" 
                  style={{ color: "#999999", fontSize: 20}}
                  onClick={()=>setPasswordVisible(!passwordVisible)} 
                  /> : 
                  <VisibilityOffOutlinedIcon
                  className="passwordVisibilityIcon" 
                  style={{ color: "#999999", fontSize: 20}}
                  onClick={()=>setPasswordVisible(!passwordVisible)} 
                  />
                }  
          </div>
      }

          {/*Submit button */
          <div className='loginButton'>
            <Button login onPress={submitHandler}>
                {mode === 'Reset password' ? 'Send reset email' : mode }
            </Button>

          </div>
          }

          {/*Log in options*/
          mode==='Log in' && 
          <>
          <Button  borderless inverse onPress={() => setMode('Reset password')} > Forgot your password? </Button> 
          <Button  borderless inverse onPress={() => setMode('Sign up')} > Don't have an account? </Button>
          <Button  borderless inverse onPress={launchGuestHandler} > Log in as a guest</Button>
          </>
          }

          {/*Reset password options*/
          mode==='Reset password' && 
          <>
          <Button borderless inverse onPress={() => setMode('Log in')} > Go back to log in </Button> 
          </>
          }

          {/*Show login option if in sign up or reset password mode */
          ( mode==='Sign up') && 
            <>
            <Button  borderless inverse onPress={() => setMode('Log in')} > Already have an account? </Button>
            <Button  borderless inverse onPress={launchGuestHandler} > Log in as a guest</Button>
            </>
          }

    
   

        {alertMessage && 
          <Alert 
          title={alertMessage.title}
          message={alertMessage.message}
          onConfirm={() => setAlertMessage()}
          />
        }

</form>

    );

}

export default Login;